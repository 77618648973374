import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Loader, Text, ToggleButton } from '@wix/design-system';
import Router, { useRouter } from 'next/router';
import { useTranslation } from '@wix/wix-i18n-config';
import StatusWarningFilledIcon from 'wix-ui-icons-common/StatusWarningFilled';
import StatusWarningIcon from 'wix-ui-icons-common/StatusWarning';
import { clickTheIHaveThisProblemBugButton, voteUnVoteForWishList } from '@wix/bi-logger-new-help-center/v2';
import { ArticleResolution, ArticleType, SourceType } from '@wix/answers-api';
import HeartIcon from 'wix-ui-icons-common/Heart';
import HeartFilledIcon from 'wix-ui-icons-common/HeartFilled';
import ThumbsUp from 'wix-ui-icons-common/ThumbsUp';
import ThumbsUpFilled from 'wix-ui-icons-common/ThumbsUpFilled';
import { DATA_HOOKS } from '../../dataHooks';
import { AUTO_VOTE, BI, LOCALES } from '../../constants';
import { mapArticleTypeToItemType } from '../../utils/bi';
import { useBI } from '../../hooks/useBI';
import { Context } from '../../context';
import { isMobile } from '../../utils/userAgent';
import css from './index.module.scss';
import { COLORS } from '../../utils/theme';
import { LoginToGetUpdates } from './LoginToGetUpdates';
import { CtaEngagementText } from '../CtaEngagementText';
import { ItemOnPageEventSource, useBiOnVisibleItem } from '../../hooks/useBiOnVisibleItem';
import { useVoteType } from '../../hooks/useVoteType';
import { ArticleSearchResultItem, VoteType } from '../../types';
import { OptInModalStatus, useOptInUtils } from '../../hooks/useOptInUtils';
import { OptInModal } from '../OptInModal';
import { Flag, FlagFilled } from 'wix-ui-icons-common';
import classNames from 'classnames';
import { useRoadmapVoteBi } from '../RoadmapArticleList/RoadmapVote/bi';

export type VoteProps = {
  article: ArticleSearchResultItem;
  isOptedIn: boolean;
  isOptInStatusLoading: boolean;
  hasNoContent?: boolean;
  toggleIsOptedIn: () => void;
};

const roadMapVoteTypeToBiItemName: Record<VoteType, string> = {
  [VoteType.VOTE]: BI.ITEM_NAMES.VOTE.FEATURE_REQUEST_VOTE,
  [VoteType.FOLLOW]: BI.ITEM_NAMES.VOTE.FEATURE_REQUEST_FOLLOW,
  [VoteType.ENROLLMENT]: BI.ITEM_NAMES.VOTE.FEATURE_REQUEST_FOLLOW,
};

export const Vote: FunctionComponent<VoteProps> = ({ article, hasNoContent, toggleIsOptedIn, isOptedIn, isOptInStatusLoading }: VoteProps) => {
  const { id, type, resolution } = article;
  const { t } = useTranslation();
  const { sendBIEvent } = useBI();
  const { locale } = useRouter();
  const context = useContext(Context);
  const isMobileView = isMobile(context);
  const [isVoted, setIsVoted] = useState(false);
  const [showVoteContent, setShowVoteContent] = useState(false);
  const roadMapVoteType = useVoteType(article);
  const { isLoggedInUser, answersApi, isWixStudioUser } = context;
  const [optInModalStatus, setOptInModalStatus] = React.useState<OptInModalStatus>(OptInModalStatus.Closed);
  const { optIn, optOut } = useOptInUtils(article, locale || LOCALES.EN, toggleIsOptedIn, setOptInModalStatus, setShowVoteContent);
  const biLogger = useRoadmapVoteBi();

  const itemName = useMemo(() => {
    if (type === ArticleType.KNOWN_ISSUE) {
      return BI.ITEM_NAMES.VOTE.KNOWN_ISSUE_VOTE;
    }
    return roadMapVoteTypeToBiItemName[roadMapVoteType];
  }, [roadMapVoteType, type]);

  const { itemRef, resetItemOnPageEvent } = useBiOnVisibleItem(
    ItemOnPageEventSource.FEATURE_REQUEST_FOLLOW,
    {
      item_id: id,
      item_name: itemName,
    }
  );

  useEffect(() => {
    void (async () => {
      if (!isLoggedInUser) {
        return;
      }
      const preVote = await answersApi.articles.isFollowingArticle({
        id,
        locale: locale as string,
      }).catch(() => false);
      setIsVoted(preVote);
    })();
  }, [answersApi.articles, id, isLoggedInUser, locale]);

  useEffect(() => {
    resetItemOnPageEvent();
    void (async () => {
      if (id) {
        if (window !== undefined) {
          const hasAutoVote = new URL(location.href).searchParams.get(
            AUTO_VOTE
          );
          if (hasAutoVote) {
            setIsVoted(true);
            setShowVoteContent(true);
            await context.answersApi.articles.voteOnIssueOrFeature({
              sourceId: null,
              locale: locale as string,
              id,
              sourceType: SourceType.HELP_CENTER,
              isProUser: isWixStudioUser
            });

            await Router.replace(location.pathname, undefined, {
              shallow: true,
              locale,
            });
          }
        }
      }
    })();
  }, [id]);

  const successMessage = useMemo(() => {
    if (type === ArticleType.KNOWN_ISSUE) {
      return t('known-issue.following-message');
    }
    return t('feature.request.logged-in.success.msg');
  }, [isLoggedInUser, type]);

  const vote = async () => {
    if (type === ArticleType.FEATURE_REQUEST && roadMapVoteType === VoteType.ENROLLMENT) {
      if (isOptedIn) {
        return optOut();
      }
      biLogger.optInClick({ articleId: article.id, clickedText: t('feature.request.opt-in.button') });
      return setOptInModalStatus(OptInModalStatus.Confirm);
    }
    setIsVoted(!isVoted);
    setShowVoteContent(!isVoted);
    const dto = {
      sourceId: null,
      locale: locale as string,
      id,
      sourceType: SourceType.HELP_CENTER,
      isProUser: isWixStudioUser
    };
    if (isVoted) {
      await context.answersApi.articles.unvoteOnIssueOrFeature(dto);
    } else {
      await context.answersApi.articles.voteOnIssueOrFeature(dto);
    }
    const biDto = {
      source_name: BI.SOURCE_NAMES.ARTICLE,
      kb_lang: locale as string,
      item_id: id,
      item_type: mapArticleTypeToItemType(type),
      thumbs_up: !isVoted,
    };
    if (type === ArticleType.FEATURE_REQUEST) {
      await sendBIEvent(voteUnVoteForWishList({
        ...biDto,
        vote_type: roadMapVoteType === VoteType.FOLLOW
          ? 'feature_request_follow'
          : 'feature_request_vote',
      }));
    } else if (type === ArticleType.KNOWN_ISSUE) {
      await sendBIEvent(clickTheIHaveThisProblemBugButton(biDto));
    }
  };

  const showVoteContentOfRoadMap = roadMapVoteType === VoteType.FOLLOW && showVoteContent;

  const isKnownIssue = type === ArticleType.KNOWN_ISSUE;

  const { text: buttonText, icon: buttonIcon } = useMemo(() => {
    if (type === ArticleType.KNOWN_ISSUE) {
      return isVoted
        ? { text: t('known.issue.following-this-issue'), icon: <StatusWarningFilledIcon /> }
        : { text: t('known.issue.follow-this-issue'), icon: <StatusWarningIcon /> };
    }
    if (roadMapVoteType === VoteType.VOTE) {
      return isVoted
        ? { text: t('feature.request.voted.button'), icon: <ThumbsUpFilled /> }
        : { text: t('feature.request.vote.button'), icon: <ThumbsUp /> };
    }
    if (roadMapVoteType === VoteType.ENROLLMENT) {
      return isOptedIn
        ? { text: t('feature.request.opted-in.button'), icon: <FlagFilled /> }
        : { text: t('feature.request.opt-in.button'), icon: <Flag /> };
    }
    return isVoted
      ? { text: t('feature.request.following.button'), icon: <HeartFilledIcon /> }
      : { text: t('feature.request.follow.button'), icon: <HeartIcon /> };

  }, [isVoted, roadMapVoteType, type, isOptedIn]);

  const voteToggleButtonLabel = useMemo(() => (
    <Text weight="normal" size="medium" className={css.followButtonText}>
      {buttonText}
    </Text>
  ), [buttonText]);

  const shouldHideButton = resolution === ArticleResolution.RESOLVED;
  const isPreLaunch = article.resolution && [ArticleResolution.COMING_SOON, ArticleResolution.PENDING_RELEASE].includes(article.resolution);
  const voteButtonDisabled = React.useMemo(() => (isOptedIn && roadMapVoteType === VoteType.ENROLLMENT && isPreLaunch) || (isOptInStatusLoading), [isOptedIn, roadMapVoteType, article.resolution, isOptInStatusLoading]);

  const isToggleButtonSelected = React.useMemo(() => {
    if (type === ArticleType.FEATURE_REQUEST && roadMapVoteType === VoteType.ENROLLMENT) {
      return isOptedIn;
    }
    return isVoted;
  }, [isVoted, isOptedIn, roadMapVoteType, type]);

  const voteContent = React.useMemo(() => {
    if (voteButtonDisabled && !isOptInStatusLoading) {
      return (
        <Text dataHook={DATA_HOOKS.VOTE_TEXT} className={css.voteMessage} weight='thin' size='small'>
          {t('roadmap.article-item.vote-content-enrollment-disabled')}
        </Text>
      );
    }
    if (isLoggedInUser && isVoted && type === ArticleType.FEATURE_REQUEST && roadMapVoteType === VoteType.VOTE) {
      return (
        <div aria-live="polite">
          <Text dataHook={DATA_HOOKS.VOTE_TEXT} className={css.voteMessage} weight={isMobileView ? 'thin' : 'normal'} size="small">
            {successMessage}
          </Text>
        </div>
      );
    }
    if (showVoteContent && roadMapVoteType === VoteType.ENROLLMENT) {
      return (
        <Text dataHook={DATA_HOOKS.VOTE_TEXT} className={css.voteMessage} weight='thin' size='small'>
          {t('roadmap.article-item.enrollment-vote-content')}
        </Text>
      );
    }
    return null;
  }, [showVoteContent, voteButtonDisabled, isOptInStatusLoading, isVoted, type, roadMapVoteType, isLoggedInUser, t, isMobileView, successMessage]);

  if (resolution === ArticleResolution.RELEASED) {
    return null;
  }

  return (
    <>
      <Box direction="vertical" gap='SP2'
        className={classNames(css.voteSection, {
          [css.hasNoContent]: hasNoContent,
          [css.hasNoContentNew]: hasNoContent,
        })}
        dataHook={DATA_HOOKS.VOTE_SECTION}>
        {isKnownIssue ? (
          <CtaEngagementText articleType={type} articleResolution={resolution} />
        ) : null}
        <Box direction={isMobileView ? 'vertical' : 'horizontal'} verticalAlign="middle" align="left">
          <div>
            {!shouldHideButton ? (
              <ToggleButton
                className={css.followButton}
                shape="pill"
                color={COLORS.B50}
                size='large'
                border={!isToggleButtonSelected}
                dataHook={DATA_HOOKS.FOLLOW_VOTE_ARTICLE_BTN}
                labelValue={voteToggleButtonLabel}
                selected={isToggleButtonSelected}
                skin={'standard'}
                labelPlacement="end"
                onClick={vote}
                disabled={voteButtonDisabled}
              >
                {isOptInStatusLoading ? <Loader size='tiny' /> : buttonIcon}
              </ToggleButton>
            ) : null}
            <div ref={itemRef} />
          </div>
          {isLoggedInUser && showVoteContentOfRoadMap && (
            <Text className={css.voteContent} weight='normal' size="small">
              {t('roadmap.article-item.vote-content')}
            </Text>
          )}
        </Box>
        {voteContent}
        {!isLoggedInUser && isVoted ? (
          <LoginToGetUpdates type={type} id={id} />
        ) : null}
      </Box>
      <OptInModal status={optInModalStatus} setStatus={setOptInModalStatus} article={article} onOptIn={optIn} onOptOut={optOut} />
    </>
  );
};
