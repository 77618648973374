import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FloatingNotification } from '@wix/design-system';
import { useRouter } from 'next/router';
import { StatusWarning as StatusWarningIcon } from 'wix-ui-icons-common';
import { useTranslation } from '@wix/wix-i18n-config';
import { Article } from '@wix/answers-api';
import { DATA_HOOKS } from '../../dataHooks';
import { AB_TESTING_LABELS } from '../../types';
import { useArticlePageBi } from '../../utils/articlePageBI';
import { Context } from '../../context';

type ArticleABTestNotificationProps = {
  article: Article;
};

export const ArticleABTestNotification: FunctionComponent<ArticleABTestNotificationProps> = ({ article }) => {
  const { isWixEmployee } = useContext(Context);
  const [isDisplay, setIsDisplay] = useState(isWixEmployee && article.abTestInfo?.experimentId);
  const { locale, query: { alternativeContent } } = useRouter();
  const { t } = useTranslation();

  const {
    articleIsBeingAbTestedView,
    articleIsBeingAbTestedClick,
  } = useArticlePageBi(article);

  useEffect(() => {
    void (async () => {
      if (isDisplay) {
        const labelText = t('article.ab-test-notification', {
          version: alternativeContent === 'true'
            ? AB_TESTING_LABELS.B
            : AB_TESTING_LABELS.A,
        });
        const ctaText = t('article.ab-test-notification-open-version', {
          version: alternativeContent === 'true'
            ? AB_TESTING_LABELS.A
            : AB_TESTING_LABELS.B,
        });
        const text = `${labelText} ${ctaText}`;
        await articleIsBeingAbTestedView(text);
      }
    })();
  }, [alternativeContent, articleIsBeingAbTestedView, isDisplay]);

  const abTestNotificationClick = async () => {
    const labelText = t('article.ab-test-notification', {
      version: alternativeContent ? AB_TESTING_LABELS.B : AB_TESTING_LABELS.A,
    });
    const ctaText = t('article.ab-test-notification-open-version', {
      version: alternativeContent ? AB_TESTING_LABELS.A : AB_TESTING_LABELS.B,
    });
    const text = `${labelText} ${ctaText}`;

    await articleIsBeingAbTestedClick(
      text,
      ctaText,
      `${locale}${article.uri}?alternativeContent=${!alternativeContent}`
    );
  };


  return (
    isDisplay ? <FloatingNotification
      prefixIcon={<StatusWarningIcon />}
      dataHook={DATA_HOOKS.ARTICLE_AB_TEST_NOTIFICATION}
      type="warning"
      onClose={() => setIsDisplay(false)}
      text={t('article.ab-test-notification', {
        version: alternativeContent
          ? AB_TESTING_LABELS.B
          : AB_TESTING_LABELS.A,
      })}
      textButtonProps={{
        label: t('article.ab-test-notification-open-version', {
          version: alternativeContent
            ? AB_TESTING_LABELS.A
            : AB_TESTING_LABELS.B,
        }),
        as: 'a',
        onClick: abTestNotificationClick,
        href: `${locale}${article.uri}?alternativeContent=${!alternativeContent}`,
        target: '_blank',
      }}
      fullWidth
    /> : null
  );
};
