import React, { FunctionComponent, useContext } from 'react';
import { FloatingNotification, TextButton, Text } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import InfoCircleIcon from 'wix-ui-icons-common/InfoCircle';
import ExternalLinkSmallIcon from 'wix-ui-icons-common/ExternalLinkSmall';
import { DATA_HOOKS } from '../../dataHooks';
import css from './index.module.scss';
import { TOOLTIP_ENTER_DELAY } from '../../constants';
import { Context } from '../../context';
import { isMobile, isTablet } from '../../utils/userAgent';
import { useRouter } from 'next/router';

export type EditorXNotificationProps = {
  onClose: () => void;
  isArticle?: boolean;
};
export const EditorXNotification: FunctionComponent<
  EditorXNotificationProps
> = ({ isArticle, onClose }) => {
  const { t } = useTranslation();
  const context = useContext(Context);
  const isMobileView = isMobile(context);
  const isTabletView = isTablet(context);
  const { locale } = useRouter();

  const loggedInLink = `https://support.wix.com/${locale}/article/wix-studio-faqs-migrating-from-editor-x-to-the-studio-editor`;

  const getText = () =>
    isArticle
      ? context.isLoggedInUser
        ? t('editor-x.notification.article.logged-in')
        : t('editor-x.notification.article')
      : context.isLoggedInUser
      ? t('editor-x.notification.logged-in')
      : t('editor-x.notification');

  const text = (
    <Text
      size="small"
      className={css.notificationText}
      tooltipProps={{ enterDelay: TOOLTIP_ENTER_DELAY }}
      ellipsis={false}
    >
      {getText()}
      <TextButton
        skin="dark"
        as="a"
        target="_blank"
        size="small"
        href={
          context.isLoggedInUser
            ? loggedInLink
            : t('editor-x.notification.learn-more.link')
        }
        underline="always"
        className={css.learnMore}
        suffixIcon={<ExternalLinkSmallIcon />}
      >
        {context.isLoggedInUser
          ? t('editor-x.notification.learn-more.logged-in')
          : t('editor-x.notification.learn-more')}
      </TextButton>
    </Text>
  );

  return <FloatingNotification
      type="preview"
      showCloseButton={true}
      fullWidth={isMobileView && !isTabletView}
      className={css.editorXNotification}
      dataHook={DATA_HOOKS.EDITOR_X_NOTIFICATION}
      onClose={onClose}
      text={text}
      prefixIcon={<InfoCircleIcon />}
    />;
};
