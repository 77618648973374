import React, {
  FunctionComponent,
  useState,
} from 'react';
import { FloatingNotification } from '@wix/design-system';
import { DATA_HOOKS } from '../../dataHooks';
import { useRouter } from 'next/router';
import { StatusWarning as StatusWarningIcon } from 'wix-ui-icons-common';
import { useTranslation } from '@wix/wix-i18n-config';
import { useExperiments } from '@wix/fe-essentials-standalone';
import { EXPERIMENTS } from '../../constants';

export const ArticlePreviewNotification: FunctionComponent = () => {
  const { query: { preview } } = useRouter();
  const { experiments } = useExperiments({ readOnly: true });
  const [isDisplay, setIsDisplay] = useState(preview === 'true' && experiments.enabled(EXPERIMENTS.SPECS.ARTICLE_PREVIEW));
  const { t } = useTranslation();
  return (
    isDisplay ? <FloatingNotification
      prefixIcon={<StatusWarningIcon />}
      dataHook={DATA_HOOKS.ARTICLE_PREVIEW_NOTIFICATION}
      type="warning"
      fullWidth
      onClose={() => {
        setIsDisplay(false);
      }}
      text={t('article.preview-notification')}
    /> : null
  );
};
