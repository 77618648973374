/* eslint-disable max-lines*/
import React, {
  useEffect,
  useState,
  FunctionComponent,
  useCallback,
  KeyboardEvent,
  useRef,
  useContext,
} from 'react';
import { Box, Card, Search } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { useRouter } from 'next/router';
import { gotNoAnswer } from '@wix/bi-logger-new-help-center/v2';
import {
  useHttpClient,
} from '@wix/fe-essentials-standalone';
import { v4 as uuidv4 } from 'uuid';
import { DATA_HOOKS } from '../../dataHooks';
import {
  MAX_SEARCH_LENGTH,
} from '../../constants';
import {
  ArticleSearchResult,
  ArticleSearchResultItem,
  PageType,
  SearchResultItem,
  PAGES,
} from '../../types';
import { useBI } from '../../hooks/useBI';
import { mapPageTypeToSourceName } from '../../utils/bi';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import css from './index.module.scss';
import { pushUrl } from '../InnerLink';
import { SearchBarResults } from './SearchBarResults';
import { EmptySearchResults } from './EmptySearchResults';
import classNames from 'classnames';
import { ClickOnResultBI, SearchQuestionBIParams, useSearchBarBI } from './bi';
import { Context } from '../../context';

type SearchBarProps = {
  pageType: PageType;
  itemId?: string;
  itemType?: string;
  homepageNewDesign?: boolean;
};

export const SearchBar: FunctionComponent<SearchBarProps> = ({
  pageType,
  itemId,
  itemType,
  homepageNewDesign,
}: SearchBarProps) => {
  const { t } = useTranslation();
  const { locale, asPath } = useRouter();
  const httpClient = useHttpClient();
  const [hideResults, setHideResults] = useState(false);
  const { sendBIEvent } = useBI();
  const { searchSessionId, setSearchSessionId } = useContext(Context);
  const ref = useOutsideClick(() => {
    setHideResults(true);
  });

  const searchInputRef = useRef<Search>(null);

  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [articles, setArticles] = useState<ArticleSearchResultItem[] | null>(
    null
  );
  const [focusedArticleIndex, setFocusedArticleIndex] = useState<number>(-1);
  const [focusedResult, setFocusedResult] = useState<SearchResultItem | null>(
    null
  );

  const isNotHomePage = pageType !== PAGES.HOMEPAGE;

  const resultsListRef = useRef(null);

  const { searchQuestionBI, clickAllResultsBI, clickOnResultBI } =
    useSearchBarBI();

  const ITEMS_TO_DISPLAY = 6;

  const sendNoResultsBiEvent = useCallback(
    async (searchMethod: string | undefined, searchVersion: string | undefined, newSearchSessionId: string) => {
      await sendBIEvent(
        gotNoAnswer({
          kb_lang: locale as string,
          source_name: mapPageTypeToSourceName(pageType),
          search_method: searchMethod,
          search_version: searchVersion,
          question: inputText,
          search_session_id: newSearchSessionId
        })
      );
    },
    [locale, pageType, itemType, inputText]
  );

  const search = useCallback(async (): Promise<
    [ArticleSearchResultItem[], string | undefined, string | undefined]
  > => {
    let newArticles: ArticleSearchResultItem[] = [];
    let searchMethod: string | undefined;
    let searchVersion: string | undefined;

    if (inputText?.length > 0) {
      setIsLoading(true);
      const { data } = await httpClient.get<ArticleSearchResult>(
        '/api/article/search',
        {
          params: {
            locale,
            text: inputText,
          },
        }
      );

      newArticles = data.items.slice(0, ITEMS_TO_DISPLAY);
      searchMethod = data.searchMethod;
      searchVersion = data.searchVersion;
    } else {
      setArticles(null);
    }
    return [newArticles, searchMethod, searchVersion];
  }, [inputText]);

  const onClear = () => {
    setInputText('');
    setArticles(null);
    setFocusedArticleIndex(-1);
  };

  const onSearchResultClick = async (
    article: ArticleSearchResultItem,
    index: number,
  ): Promise<void> => {
    const biArticleClickParams: ClickOnResultBI = {
      url: article.uri,
      index,
      resultSourceType: article.type,
      docType: article.docType,
      inputText,
      searchSessionId,
      pageType,
      clickedItemId: article.id
    };

    await clickOnResultBI(biArticleClickParams);
    onClear();
  };

  useEffect(() => {
    onClear();
  }, [asPath]);

  useEffect(() => {
    if (inputText !== '') {
      Promise.allSettled([search()])
        .then(async ([returnedArticles]) => {
          const [foundArticles, searchMethod, searchVersion] =
            returnedArticles.status === 'fulfilled'
              ? returnedArticles.value
              : [[], undefined, undefined];
          setArticles(foundArticles as ArticleSearchResultItem[]);
          const newSearchSessionId = uuidv4();
          setSearchSessionId(newSearchSessionId);

          const searchQuestionBIParams: SearchQuestionBIParams = {
            results: foundArticles,
            pageType,
            inputText,
            searchSessionId: newSearchSessionId,
            searchMethod,
            searchVersion,
            itemId
          };
          await searchQuestionBI(searchQuestionBIParams);
          if (foundArticles.length === 0) {
            void sendNoResultsBiEvent(searchMethod, searchVersion, newSearchSessionId);
          }

          setFocusedArticleIndex(-1);
          setFocusedResult(null);
          setIsLoading(false);
        })
        .catch(() => false);
    }
  }, [inputText, search]);

  const scrollIntoViewIfNeeded = () => {
    if (resultsListRef.current) {
      const focusedItem = (resultsListRef.current as HTMLElement).querySelector(
        `[data-index="${focusedArticleIndex}"]`
      );
      if (focusedItem) {
        focusedItem.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    }
  };

  const handleKeyboardEvent = useCallback(
    async (event: KeyboardEvent) => {
      const resultsCount = articles?.length ?? 0;

      switch (event.key) {
        case 'ArrowDown':
          setFocusedArticleIndex(
            focusedArticleIndex === resultsCount
              ? focusedArticleIndex
              : focusedArticleIndex + 1
          );
          scrollIntoViewIfNeeded();
          break;
        case 'ArrowUp':
          setFocusedArticleIndex(
            focusedArticleIndex > -1
              ? focusedArticleIndex - 1
              : focusedArticleIndex
          );
          scrollIntoViewIfNeeded();
          break;
        case 'Enter':
          if (
            articles &&
            focusedArticleIndex >= 0 &&
            focusedArticleIndex <= resultsCount - 1
          ) {
            await onSearchResultClick(
              articles[focusedArticleIndex],
              focusedArticleIndex
            );
            await pushUrl(articles[focusedArticleIndex].uri);
          } else if (focusedArticleIndex === articles?.length) {
            await pushUrl(`${locale}/search?term=${inputText}`);
          }
          break;
        case 'Escape':
          setArticles(null);
          setFocusedArticleIndex(-1);
          break;
      }
    },
    [articles, focusedArticleIndex, inputText, focusedResult]
  );

  const onSearchChange = useCallback((e: { target: { value: string } }) => {
    setInputText(e.target.value.trim());
    setFocusedArticleIndex(-1);
  }, []);

  const onManuallyInput = useCallback(
    async (val: string) => {
      if (focusedArticleIndex === -1) {
        setInputText(val.trim());
      }
    },
    [focusedArticleIndex]
  );

  const onSearchBarInputEnter = async () => {
    if (areResultsPresent && focusedArticleIndex === -1) {
      await pushUrl(`${locale}/search?term=${inputText}`);
    }
  };

  const onSearchFocus = useCallback(() => {
    setHideResults(false);
    setFocusedArticleIndex(-1);
  }, []);

  const onClickAllResults = () =>
    clickAllResultsBI(
      inputText,
      pageType,
      searchSessionId,
      onClear,
      itemId,
      undefined,
      'general'
    );

  const placeholder = isNotHomePage
    ? t('subheader-searchbar.placeholder')
    : t('searchbar.placeholder');

  const areResultsPresent = articles && articles.length > 0;

  const isEmptyResultsVisible = !areResultsPresent && !isLoading;

  const isSearchInputEmpty = !(searchInputRef.current?.state as any)
    ?.inputValue;
  return (
    <div
      ref={ref}
      className={css.wrapper}
      data-hook={DATA_HOOKS.SEARCH_BAR}
      onKeyUp={handleKeyboardEvent}
    >
      <Box
        direction="vertical"
        verticalAlign="middle"
        className={classNames({
          [css.searchBarWrapperHomepage]: pageType === PAGES.HOMEPAGE,
          [css.searchBarWrapperSubHeader]: pageType !== PAGES.HOMEPAGE,
          [css.homepageNewDesign]: homepageNewDesign,
          [css.newSubheaderDesign]: isNotHomePage,
          [css.focusSearchBar]: !isSearchInputEmpty || !hideResults,
        })}
      >
        <Search
          ref={searchInputRef}
          debounceMs={1000}
          maxLength={MAX_SEARCH_LENGTH}
          placeholder={placeholder}
          onChange={onSearchChange}
          onClear={onClear}
          onManuallyInput={onManuallyInput}
          value={inputText}
          size={isNotHomePage ? 'large' : undefined}
          className={classNames({
            [css.searchBar]: !isNotHomePage,
          })}
          dataHook={DATA_HOOKS.SEARCH_BAR_INPUT}
          textOverflow={inputText.length > 0 ? undefined : 'ellipsis'}
          onFocus={onSearchFocus}
          autoFocus={pageType === PAGES.HOMEPAGE && homepageNewDesign}
          onEnterPressed={onSearchBarInputEnter}
        />
        {articles && inputText !== '' && !hideResults && (
          <Card
            showShadow
            className={classNames({
              [css.searchBarResultsWrapperHomepage]:
                pageType === PAGES.HOMEPAGE,
              [css.searchBarResultsWrapperSubHeader]:
                pageType !== PAGES.HOMEPAGE,
            })}
          >
            {areResultsPresent && (
              <SearchBarResults
                resultsListRef={resultsListRef}
                articles={articles}
                inputText={inputText}
                focusedArticleIndex={focusedArticleIndex}
                onClickAllResults={onClickAllResults}
                onSearchResultClick={onSearchResultClick}
                setFocusedArticleIndex={setFocusedArticleIndex}
                setFocusedResult={setFocusedResult}
                pageType={pageType}
              />
            )}
            {isEmptyResultsVisible && (
              <EmptySearchResults inputText={inputText} />
            )}
          </Card>
        )}
      </Box>
    </div>
  );
};
