import { FunctionComponent } from 'react';
import css from './index.module.scss';

export type BreadcrumbsStructuredDataProps = {
  index: number;
  children: JSX.Element;
};

export const BreadcrumbsStructuredData: FunctionComponent<
  BreadcrumbsStructuredDataProps
> = ({ index, children }: BreadcrumbsStructuredDataProps) => (
  <li
    itemProp="itemListElement"
    className={css.breadcrumbsStructuredData}
    itemScope
    itemType="https://schema.org/ListItem"
  >
    {children}
    <meta itemProp="position" content={(index - 1).toString()} />
  </li>
);
