import React, { FunctionComponent, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Box, Button, Text } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { pageError } from '@wix/bi-logger-new-help-center/v2';
import { DATA_HOOKS } from '../../dataHooks';
import { HttpStatusCode } from '../../types';
import { useBI } from '../../hooks/useBI';
import { useHistoryContext } from '../../hooks/useRouteHistory';
import { mapReferralUrlToSourceName } from '../../utils/bi';
import css from './index.module.scss';
import { InnerLink } from '../InnerLink';
import { LOCALES } from '../../constants';

interface ErrorProps {
  statusCode: number;
  messageError?: string;
  englishArticleUri?: string;
}

export const Error: FunctionComponent<ErrorProps> = ({
  statusCode,
  messageError,
  englishArticleUri,
}: ErrorProps) => {
  const { t } = useTranslation();
  const { locale, reload } = useRouter();
  const { sendBIEvent } = useBI();
  const { prePageUrl } = useHistoryContext();
  const isEnglishArticleUriExist =
    englishArticleUri && englishArticleUri.length > 0;

  const getTitle = () => {
    switch (statusCode) {
      case HttpStatusCode.NOT_FOUND:
        return t(
          isEnglishArticleUriExist
            ? 'error.404.article.subtitle'
            : 'error.404.subtitle'
        );
      case HttpStatusCode.UNAUTHORIZED:
      case HttpStatusCode.FORBIDDEN:
      case HttpStatusCode.INTERNAL_SERVER_ERROR:
      case HttpStatusCode.TIMEOUT_ERROR:
        return t(`error.${statusCode}.subtitle`);
      default:
        return t('error.404.subtitle');
    }
  };

  const getSubtitle = () => {
    if (statusCode === HttpStatusCode.TIMEOUT_ERROR) {
      return t('error.timeout.description');
    } else if (isEnglishArticleUriExist) {
      return t('error.404.article.redirect');
    } else {
      return t('error.redirect');
    }
  };

  useEffect(() => {
    void (async () => {
      await sendBIEvent(
        pageError({
          error_type_id: `${statusCode}`,
          error_type_description: messageError,
          error_origin: location.href,
          kb_lang: locale,
          referral_url: prePageUrl,
          source_name: mapReferralUrlToSourceName(prePageUrl, locale as string),
        })
      );
    })();
  }, [statusCode, locale, messageError]);


  const isTimeoutError = statusCode === HttpStatusCode.TIMEOUT_ERROR;

  return (
    <Box direction="vertical" className={css.wrapper} align="center">
      <Box direction="vertical" className={css.innerWrapper} align="center">
        <Text className={css.title}>{getTitle()}</Text>
        <Text className={css.subtitle}>{getSubtitle()}</Text>
        <Box className={css.ctaWrapper}>
        {isTimeoutError ? (
            <Button
              size="medium"
              dataHook={DATA_HOOKS.ERROR_PAGE_REFRESH_BUTTON}
              onClick={reload}
            >
              {t('error.refresh-button')}
            </Button>
          ) : (
            <InnerLink href={'/'}>
              <Button
                priority={isEnglishArticleUriExist ? 'secondary' : 'primary'}
                size="medium"
                dataHook={DATA_HOOKS.ERROR_PAGE_GO_TO_HOMEPAGE}
                ellipsis
              >
                {t('error.redirect.cta')}
              </Button>
            </InnerLink>
          )}
          {isEnglishArticleUriExist && (
            <Button
              size="medium"
              dataHook={DATA_HOOKS.ERROR_PAGE_GO_TO_ENGLISH_ARTICLE}
              ellipsis
            >
              <InnerLink href={englishArticleUri} locale={LOCALES.EN}>
                {t('error.404.article.redirect.cta')}
              </InnerLink>
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
