import React, { FunctionComponent, useContext } from 'react';
import { Box, IconButton } from '@wix/design-system';
import { TsUtils } from '@wix/answers-api';
import AddSmall from 'wix-ui-icons-common/AddSmall';
import {
  Label,
  RoadmapETAorVotedFilter,
  RoadmapFilter,
  SortAndFilterBiMetaData,
} from '../../../types';
import { RoadmapSortAndFilter } from './../RoadmapSortAndFilter';
import { DATA_HOOKS } from '../../../dataHooks';
import { isMobile } from '../../../utils/userAgent';
import { Context } from '../../../context';
import { ApplySortAndFilterFn } from '..';
import css from './index.module.scss';
import { useRoadmapHeaderBi } from '../RoadmapHeader/bi';

export type RoadmapFiltersHeaderProps = {
  roadmapFilter: RoadmapFilter;
  onChangeRoadmapFilter: (filter: RoadmapFilter) => void;
  labels: Label[];
  apply: ApplySortAndFilterFn;
  onSearch: (text: string) => void;
  isScrolled: boolean;
  onChangeTriggerBiMetaData: (
    triggerBiMetaData: SortAndFilterBiMetaData
  ) => void;
  setShowDesktopOnlyModal: (showDesktopOnlyModal: boolean) => void;
  locale: string;
  setFeatureRequestModalVisible: (featureREquestModalVisible: boolean) => Promise<void>;
};

export const allFilterOptions = TsUtils.enumToArray(
  RoadmapETAorVotedFilter
) as RoadmapETAorVotedFilter[];

export const RoadmapFiltersHeader: FunctionComponent<
  RoadmapFiltersHeaderProps
> = ({
  roadmapFilter,
  onChangeRoadmapFilter,
  labels,
  apply,
  isScrolled,
  onChangeTriggerBiMetaData,
  setShowDesktopOnlyModal,
  setFeatureRequestModalVisible
}) => {
  const context = useContext(Context);
  const { isWixStudioUser } = context;
  const isMobileView = isMobile(context);
  const biLogger = useRoadmapHeaderBi();

  const onClickFeatureRequest = async () => {
    if (isMobileView) {
      biLogger.onRequestFeatureClickFromMobile();
      setShowDesktopOnlyModal(true);
    } else {
      biLogger.onRequestFeatureClick();
      await setFeatureRequestModalVisible(true);
    }
  };

  return (
    <Box
      className={css.roadmapFilterHeader}
      align={isMobileView ? 'right' : undefined}
    >
      <RoadmapSortAndFilter
        roadmapFilter={roadmapFilter}
        onChangeFilter={onChangeRoadmapFilter}
        productLabels={labels}
        applyValue={apply}
        isScrolled={isScrolled}
        onChangeTriggerBiMetaData={onChangeTriggerBiMetaData}
      />
      {isWixStudioUser && isMobileView && (
        <IconButton
          onClick={onClickFeatureRequest}
          className={css.requestFeatureIconButton}
          dataHook={DATA_HOOKS.ROADMAP_FEATURE_REQUEST_BUTTON}
          priority="secondary"
          size="tiny"
        >
          <AddSmall height={'11px'} width={'11px'} />
        </IconButton>
      )}
    </Box>
  );
};
