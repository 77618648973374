import React, { FunctionComponent, useContext } from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import { Tag, Text, Tooltip } from '@wix/design-system';
import { ArticleResolution } from '@wix/answers-api';
import Long from 'long';
import { RoadmapResolutionBadge } from '../RoadmapResolutionBadge';
import { DATA_HOOKS } from '../../dataHooks';
import { Context } from '../../context';
import { isMobile } from '../../utils/userAgent';
import css from './index.module.scss';
import { getQuarter } from '../../utils/roadmap';

type RoadmapResolutionEtaProps = {
  wrapEtaWithTag?: boolean;
  resolution: ArticleResolution | null;
  resolutionEta?: Long | number;
  onClickStatus?: (articleResolution: ArticleResolution) => void;
};

export const RoadmapResolutionEta: FunctionComponent<
  RoadmapResolutionEtaProps
> = ({ resolution, resolutionEta, onClickStatus, wrapEtaWithTag }) => {
  const { t } = useTranslation();
  const context = useContext(Context);
  const isMobileView = isMobile(context);

  const renderEta = (eta: Long | number) => {
    const { quarter, year } = getQuarter(eta); // ?
    return (
      <Text
        dataHook={DATA_HOOKS.ARTICLE_ITEM_ETA}
        size={isMobileView ? 'tiny' : 'small'}
        weight="thin"
        className={css.eta}
      >
        {t('roadmap.article-item.eta', { quarter, year })}
      </Text>
    );
  };

  return (
    <div className={`${css.roadmapResolutionEta} ${onClickStatus ? 'clickable' : ''}`}>
      {resolution && (
        <RoadmapResolutionBadge
          resolution={resolution}
          size="small"
          resolutionEta={resolutionEta}
          onClickStatus={onClickStatus}
        />
      )}
      {resolutionEta &&
        resolution !== ArticleResolution.RELEASED &&
        <Text className={css.spacingDot}>·</Text>}
      {resolutionEta && resolution !== ArticleResolution.RELEASED && (
        <Tooltip
          size="small"
          disabled={!onClickStatus}
          content={t(`roadmap.article-list.resolution.tooltip.eta.${getQuarter(resolutionEta).quarter}`,
            { year: `${getQuarter(resolutionEta).year}` }
          )}
        >
          {!wrapEtaWithTag || isMobileView ? (
            renderEta(resolutionEta)
          ) : (
            <Tag
              size="small"
              className={`${css.etaTag}`}
              id={'eta'}
              theme="standard"
              removable={false}
            >
              <div className={`${css.etaBody}`}>{renderEta(resolutionEta)}</div>
            </Tag>
          )}
        </Tooltip>
      )}
    </div>
  );
};
