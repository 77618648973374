/* eslint-disable max-lines */
export const DATA_HOOKS = {
  HOME_PAGE: 'home-page',
  SEARCH_RESULT: 'search-result',
  SEARCH_BAR: 'search-bar',
  SEARCH_BUTTON: 'search-button',
  SEARCH_BAR_INPUT: 'search-bar-input',
  SEARCH_RESULT_TITLE: 'search-result-title',
  SEARCH_RESULT_DESCRIPTION: 'search-result-description',
  EMPTY_SEARCH_RESULTS: 'empty-search-results',
  EMPTY_SEARCH_RESULTS_TEXT: 'empty-search-results-text',
  SEARCH_BAR_RESULTS: 'search-bar-results',
  SEARCH_BAR_RESULT_TYPE: 'search-bar-result-type',
  SEARCH_BAR_RESULT_TYPE_TITLE: 'search-bar-result-type-title',
  SEARCH_BAR_RESULT_TYPE_MORE: 'search-bar-result-type-more',
  SEARCH_BAR_RESULT_ICON: 'search-bar-result-icon',
  SEARCH_BAR_RESULT_GROUP: 'search-bar-result-group',
  SHOW_ALL_SEARCH_RESULTS: 'show-all-search-results',
  BREADCRUMBS: 'breadcrumbs',
  BREADCRUMB: 'breadcrumb-link',
  BREADCRUMB_CATEGORY_MENU: 'breadcrumb-category-menu',
  BREADCRUMB_ITEM_SEPARATOR: 'breadcrumb-item-separator',
  HELPFUL_YES: 'helpful-yes',
  HELPFUL_NO: 'helpful-no',
  UNHELPFUL_OPTIONS: 'unhelpful-options',
  SUBMIT_FEEDBACK: 'submit-feedback',
  THANKS_MESSAGE: 'thanks-for-feedback',
  ARTICLE_FEEDBACK: 'article-feedback',
  ARTICLE_FEEDBACK_TITLE: 'article-feedback-title',
  ARTICLE_AB_TEST_NOTIFICATION: 'article-ab-test-notification',
  ARTICLE_PREVIEW_NOTIFICATION: 'article-preview-notification',
  ADDITIONAL_FEEDBACK: 'additional-feedback',
  ADDITIONAL_FEEDBACK_UNHELPFUL: 'additional-unhelpful',
  ADDITIONAL_FEEDBACK_HELPFUL: 'additional-helpful',
  ADDITIONAL_UNHELPFUL_TEXT: 'additional-unhelpful-text',
  HELPFUL_MESSAGE: 'helpful-message',
  VOTING_FEEDBACK_BUTTONS: 'voting-feedback-buttons',
  RELATED_ARTICLE_LINK: 'related-article-link',
  RELATED_ARTICLE_TEXT: 'related-article-text',
  VOTE_SECTION: 'vote-section',
  VOTE_BTN: 'vote-button',
  VOTE_TEXT: 'vote-text',
  MORE_RESULTS: 'more-results',
  ARTICLE_HEADER: 'article-header',
  ARTICLE_TYPE_DESCRIPTION: 'article-type-description',
  ARTICLE_READING_TIME: 'article-reading-time',
  ARTICLE_TYPE_TITLE: 'article-type-title',
  HIRE_PARTNER: 'hire-partner',
  HIRE_PARTNER_TITLE: 'hire-partner-title',
  HIRE_PARTNER_DESCRIPTION: 'hire-partner-description',
  SUGGESTION_BADGE: 'suggestion-badge',
  HIRE_PARTNER_CTA: 'hire-partner-cta',
  LANGUAGE_NOT_SUPPORTED_NOTIFICATION: 'language-not-supported-notification',
  LANGUAGE_SUPPORTED_NOTIFICATION: 'language-supported-notification',
  STATUS_PAGE_NOTIFICATION: 'status-page-notification',
  SCROLL_SPY_ANCHOR: 'scroll-spy-anchor',
  SUBHEADER_GO_TO_HOMEPAGE: 'sub-header-go-to-homepage',
  KB_SUBHEADER: 'kb-sub-header',
  KNOWLEDGE_AGENT_SEARCH_HERO: 'knowledge-agent-search-hero',
  SCROLL_DOWN_ARROW: 'scroll-down-arrow',
  HOME_SEARCH_INPUT: 'home-search-input',
  SEARCH_RESULTS_HEADER_HOMEPAGE_LINK: 'search-results-header-homepageLink',
  SEARCH_RESULTS_HEADER_QUERY_INFO: 'search-results-header-query_info',
  SEARCH_RESULT_CATEGORIES_CATEGORY: 'search-result-categories-category',
  SEARCH_RESULT_CATEGORIES_SUB_CATEGORY:
    'search-result-categories-sub-category',
  SEARCH_RESULT_CATEGORIES_TYPE: 'search-result-categories-type',
  SEARCH_RESULT_CATEGORIES_TITLE: 'search-result-categories-title',
  SEARCH_RESULT_CATEGORIES_DESCRIPTION: 'search-result-categories-description',
  SEARCH_RESULT_CATEGORIES_LINK: 'search-result-categories-link',
  CATEGORY_HEADER: 'category-header',
  CATEGORY_ARTICLES: 'category-articles',
  CATEGORY_ARTICLES_SECTION: 'category-articles-section',
  CATEGORY_ARTICLES_SECTION_TYPE: 'category-articles-section-type',
  CATEGORY_ARTICLES_SECTION_LINK: 'category-articles-section-link',
  CATEGORY_ARTICLES_TITLE: 'category-articles-title',
  CATEGORY_ITEM: 'category-item',
  CATEGORY_TREE_VIEWER_ITEM: 'category-tree-viewer-item',
  CATEGORY_BACK_BTN: 'category-back-btn',
  CATEGORY_MOBILE_MENU_BTN: 'category-mobile-menu-btn',
  CATEGORY_MOBILE_TITLE: 'category-mobile-title',
  SUBCATEGORY_MOBILE_TITLE: 'sub-category-mobile-title',
  ACCORDION_ITEM: 'accordion-item',
  CATEGORIES_LIST_ACCORDION: 'categories-list-accordion',
  HOMEPAGE_TITLE: 'homepage-title',
  HOMEPAGE_TRENDING_TOPICS_TITLE: 'trending-topics-title',
  HOMEPAGE_TRENDING_TOPICS_DESCRIPTION: 'trending-topics-description',
  HOMEPAGE_TRENDING_TOPICS_LINK: 'trending-topics-link',
  TICKET_OVER_YEAR_MESSAGE: 'ticket-over-year-message',
  CATEGORIES_CATEGORY: 'categories-category',
  CATEGORIES_SHOW_ALL: 'categories-show-all',
  CATEGORIES_SUBCATEGORY: 'categories-subcategory',
  TICKET_TIMELINE_ITEM_FIRSTNAME: 'ticket-timeline-item-firstName',
  TICKET_TIMELINE_ITEM_OFFICIAL_ANSWER: 'ticket-timeline-item-official-answer',
  TICKET_TIMELINE_ITEM_CREATION_DATE: 'ticket-timeline-item-creation-date',
  TICKET_TIMELINE_ITEM_SUBJECT: 'ticket-timeline-item-subject',
  TICKET_TIMELINE_ITEM_CONTENT: 'ticket-timeline-item-content',
  TICKET_NUMBER: 'ticket-number',
  TICKET_REPLIES_TITLE: 'ticket-replies-title',
  MY_PROFILE_HEADING: 'my-profile-heading',
  MY_PROFILE_NAME: 'my-profile-name',
  MY_PROFILE_JOINED: 'my-profile-joined',
  MY_PROFILE_CUSTOMER_ID: 'my-profile-customer-id',
  MY_PROFILE_AVATAR: 'my-profile-avatar',
  MY_PROFILE_BACK_TO_HC: 'my-profile-back-to-hc',
  MY_ACTIVITY: 'my-activity',
  MY_ACTIVITY_TITLE: 'my-activity-title',
  MY_ACTIVITY_TICKETS: 'my-activity-tickets',
  MY_ACTIVITY_TICKET_STATUS: 'my-activity-tickets-status',
  MY_ACTIVITY_FOLLOWED_CONTENT: 'my-activity-followed-content',
  MY_ACTIVITY_FOLLOWED_CONTENT_LIST: 'my-activity-followed-content-list',
  MY_ACTIVITY_FOLLOWED_CONTENT_TITLE: 'my-activity-followed-content-title',
  MY_ACTIVITY_FOLLOWED_CONTENT_ITEM: 'my-activity-followed-content-item',
  MY_ACTIVITY_FOLLOWED_CONTENT_ITEM_TITLE:
    'my-activity-followed-content-item-title',
  MY_ACTIVITY_FOLLOWED_CONTENT_ITEM_UNFOLLOW:
    'my-activity-followed-content-item-unfollow',
  MY_ACTIVITY_TICKETS_LIST: 'my-activity-tickets-list',
  MY_ACTIVITY_TICKETS_TITLE: 'my-activity-tickets-title',
  MY_ACTIVITY_TICKETS_ITEM: 'my-activity-tickets-item',
  MY_ACTIVITY_TICKETS_ITEM_TITLE: 'my-activity-tickets-item-title',
  MY_ACTIVITY_TICKETS_ITEM_STATUS: 'my-activity-tickets-item-status',
  MY_ACTIVITY_FOLLOWED_CONTENT_SHOW_MORE:
    'my-activity-followed-content-show-more',
  MY_ACTIVITY_TICKETS_SHOW_MORE: 'my-activity-tickets-show-more',
  UPLOAD_MODAL: 'upload-modal',
  UPLOAD_MODAL_CLOSE_BUTTON: 'modal-close-button',
  UPLOAD_MODAL_TITLE: 'upload-modal-title',
  UPLOAD_MODAL_FILENAME_TITLE: 'upload-modal-filename-title',
  UPLOAD_MODAL_DROPZONE: 'upload-modal-dropzone',
  REPLY: 'reply',
  REPLY_INPUT_AREA: 'reply-input-area',
  REPLY_SUBMIT: 'reply-submit',
  REPLY_FIRST_NAME: 'reply-first-name',
  REPLY_IMAGE_ICON: 'reply-image-icon',
  REPLY_FILE_ICON: 'reply-file-icon',
  ARTICLE_ITEM_TITLE: 'article-item-title',
  ARTICLE_ITEM_CONTENT: 'article-item-content',
  ARTICLE_ITEM_STATUS: 'article-item-status',
  FOLLOW_VOTE_ARTICLE_BTN: 'follow-vote-article-btn',
  NAV_CATEGORY_TREE: 'nav-category-tree',
  NAV_CATEGORY_TREE_CATEGORY: 'nav-category-tree-item',
  NAV_CATEGORY_TREE_ARTICLES: 'nav-category-tree-articles',
  NAV_CATEGORY_TREE_ARTICLE: 'nav-category-tree-article',
  NAV_CATEGORY_TREE_ARTICLES_LOADING: 'nav-category-tree-articles-loading',
  NAV_CATEGORY_TREE_ARTICLE_LOADING_SKELTON:
    'nav-category-tree-article-loading-skelton',
  NAV_CATEGORY_TREE_CATEGORY_NAME: 'nav-category-tree-category-name',
  NAV_CATEGORY_TREE_CATEGORY_CHEVRON: 'nav-category-tree-category-chevron',
  NAV_CATEGORY_TREE_CATEGORY_COLLAPSE: 'nav-category-tree-category-collapse',
  ARTICLE_RESOLUTION_TAB: 'article-resolution-tab',
  ARTICLE_RESOLUTION_TAB_ACTIVE: 'article-resolution-tab-active',
  ARTICLE_ITEM_LABEL: 'article-label',
  ROADMAP_HEADER: 'roadmap-header',
  ROADMAP_SUBHEADER: 'roadmap-subheader',
  ROADMAP_MOBILE_STATUS_SELECTOR: 'roadmap-status-selector',
  ROADMAP_MOBILE_FILTER_SELECTOR: 'roadmap-filter-selector',
  ROADMAP_MOBILE_SORT_SELECTOR: 'roadmap-sort-selector',
  FILTER_AND_SORT_MODAL: 'filter-and-sort-modal',
  MOBILE_SORT_AND_FILTER_MENU: 'mobile-sort-and-filter-menu',
  FILTER_AND_SORT_MODAL_BODY: 'filter-and-sort-modal-body',
  SUBCATEGORY_CARD: 'subcategory-card',
  SUBCATEGORY_CARD_FOOTER: 'subcategory-card-footer',
  SUBCATEGORY_CARD_TITLE: 'subcategory-card-title',
  SUBCATEGORY_CARD_ARTICLES: 'subcategory-card-articles',
  SUBCATEGORY_CARD_ARTICLE: 'subcategory-card-article',
  SUBCATEGORY_CARD_ARTICLE_TITLE: 'subcategory-card-article-title',
  SUBCATEGORY_CARD_SUBCATEGORIES: 'subcategory-card-subcategories',
  SUBCATEGORY_CARD_SUBCATEGORY: 'subcategory-card-subcategory',
  SUBCATEGORY_CARD_CATEGORY_TITLE: 'subcategory-card-category-title',
  SUBCATEGORY_CARD_CATEGORY_SEE_ALL: 'subcategory-card-category-see-all',
  SUBCATEGORY_CARD_CATEGORY_ELEMENTS_COUNT:
    'subcategory-card-category-elements-count',
  SUBCATEGORY_CARD_SKELTON: 'subcategory-card-skelton',
  SUBCATEGORY_CARD_ITEM_SKELTON: 'subcategory-card-item-skelton',
  SUBCATEGORY_CARD_FOOTER_SKELTON: 'subcategory-card-footer-skelton',
  MOBILE_SORT_ICON_BUTTON: 'mobile-sort-icon-button',
  ERROR_PAGE_GO_TO_HOMEPAGE: 'error-page-go-to-homepage',
  ERROR_PAGE_GO_TO_ENGLISH_ARTICLE: 'error-page-go-to-english-article',
  TRENDING_ARTICLES: 'trending-articles',
  TRENDING_ARTICLES_TITLE: 'trending-articles-title',
  TRENDING_ARTICLES_DESCRIPTION: 'trending-articles-description',
  TRENDING_ARTICLES_ITEM: 'trending-articles-item',
  TRENDING_ARTICLES_ITEM_TITLE: 'trending-articles-item-title',
  TRENDING_ARTICLES_ITEM_LABEL: 'trending-articles-item-label',
  TRENDING_ARTICLES_ITEM_LINK: 'trending-articles-item-link',
  TRENDING_ARTICLES_IMAGE: 'trending-articles-image',
  TOPICS_SECTION: 'topics-section',
  TOPICS_SECTION_TITLE: 'topics-section-title',
  TOPICS_SECTION_DESCRIPTION: 'topics-section-description',
  TOPICS_SECTION_CARD: 'topics-section-card',
  TOPICS_SECTION_CARD_IMAGE: 'topics-section-card-image',
  TOPICS_SECTION_CARD_TITLE: 'topics-section-card-title',
  TOPICS_SECTION_CARD_TITLE_LINK: 'topics-section-card-title-link',
  TOPICS_SECTION_CARD_CHILD_TOPIC: 'topics-section-card-child-topic',
  TOPICS_SECTION_CARD_SHOW_ALL: 'topics-section-card-show-all',
  MARKETPLACE_ENTRY: 'marketplace-entry',
  MARKETPLACE_ENTRY_TITLE: 'marketplace-entry-title',
  MARKETPLACE_ENTRY_DESCRIPTION: 'marketplace-entry-description',
  MARKETPLACE_ENTRY_BUTTON: 'marketplace-entry-button',
  EXPLORE_MORE_RESOURCES: 'explore-more-resources',
  EXPLORE_MORE_RESOURCES_TITLE: 'explore-more-resources-title',
  EXPLORE_MORE_RESOURCES_TOOLTIP: 'explore-more-resources-tooltip',
  EXPLORE_MORE_RESOURCES_DESCRIPTION: 'explore-more-resources-description',
  EXPLORE_MORE_RESOURCES_ITEM: 'explore-more-resources-item',
  EXPLORE_MORE_RESOURCES_ITEM_TITLE: 'explore-more-resources-item-title',
  EXPLORE_MORE_RESOURCES_ITEM_DESCRIPTION:
    'explore-more-resources-item-description',
  EXPLORE_MORE_RESOURCES_ITEM_CTA: 'explore-more-resources-item-cta',
  ROADMAP_BANNER: 'roadmap-banner',
  ROADMAP_BANNER_HEADING: 'roadmap-banner-heading',
  ROADMAP_BANNER_SUBTITLE: 'roadmap-banner-subtitle',
  ROADMAP_BANNER_CTA: 'roadmap-banner-cta',
  ROADMAP_BANNER_SUPPORTED_LANGUAGES: 'roadmap-banner-supported-languages',
  ROADMAP_FEATURE_REQUEST_MODAL_DESCRIPTION:
    'roadmap-feature-request-modal-description',
  ROADMAP_FEATURE_REQUEST_MODAL_IMPACT: 'roadmap-feature-request-modal-impact',
  ROADMAP_FEATURE_REQUEST_MODAL_SELECT_PRODUCT:
    'roadmap-feature-request-modal-select-product',
  ROADMAP_FEATURE_REQUEST_MODAL_ARTICLE_EXISTS:
    'roadmap-feature-request-modal-article-exists',
  ROADMAP_FEATURE_REQUEST_MODAL_BODY: 'roadmap-feature-request-modal-body',
  WIX_STUDIO_CATEGORY_ADDON: 'wix-studio-category-addon',
  WIX_STUDIO_CATEGORY_ADDON_ACADEMY_TITLE: 'wix-studio-category-addon-title',
  WIX_STUDIO_CATEGORY_ADDON_COMMUNITY_FORUM_TITLE:
    'wix-studio-category-addon-community-forum-title',
  WIX_STUDIO_CATEGORY_ADDON_ACADEMY_LINK:
    'wix-studio-category-addon-link-to-wix-academy',
  WIX_STUDIO_CATEGORY_ADDON_COMMUNITY_FORUM_LINK:
    'wix-studio-category-addon-link-to-wix-community-forum',
  ROADMAP_FEATURE_REQUEST_BUTTON: 'roadmap-feature-request-button',
  SEARCH_RESULTS: 'search-results',
  SEARCH_RESULTS_NO_RESULTS_FOUND: 'search-results-no-results-found',
  SEARCH_RESULTS_NO_RESULTS_FOUND_FLOATING:
    'search-results-no-results-floating',
  SEARCH_RESULTS_PAGINATION: 'search-results-pagination',
  SEARCH_RESULTS_TITLE: 'search-results-title',
  SEARCH_RESULT_ITEM: 'search-result-item',
  SEARCH_RESULT_ITEM_BADGES: 'search-result-item-badges',
  SEARCH_RESULT_ITEM_TOPIC_BADGE: 'search-result-item-topic-badge',
  SEARCH_RESULT_ITEM_CATEGORY_NAME: 'search-result-item-category-name',
  SEARCH_RESULT_ITEM_TYPE_BADGE: 'search-result-item-type-badge',
  SEARCH_RESULT_ITEM_TITLE: 'search-result-item-title',
  SEARCH_RESULT_ITEM_TITLE_LINK: 'search-result-item-title-link',
  SEARCH_RESULT_ITEM_DESCRIPTION: 'search-result-item-description',
  SEARCH_RESULT_ITEM_TIME_TO_READ: 'search-result-item-time-to-read',
  SEARCH_RESULTS_NO_RESULTS_FOUND_TITLE: 'search-results-no-results-title',
  SEARCH_RESULTS_NO_RESULTS_FOUND_DESCRIPTION:
    'search-results-no-results-description',
  SEARCH_RESULTS_NO_RESULTS_FOUND_CTA: 'search-results-no-results-cta',
  SEARCH_RESULTS_NO_RESULTS_FOR_FILTER: 'search-results-no-results-for-filter',
  ROADMAP_RESOLUTION_BADGE: 'roadmap-resolution-badge',
  SEARCH_RESULT_SOURCE_TYPE_TAG: 'search-result-source-type-tag',
  SEARCH_RESULT_COMMENTS_WATCH: 'search-result-comments-watch',
  SEARCH_RESULT_WATCH_NUMBER: 'search-result-watch-number',
  SEARCH_RESULT_COMMENTS_NUMBER: 'search-result-comments-number',
  SEARCH_RESULT_TAG_ETA: 'search-result-tag-eta',
  SEARCH_RESULT_STATUS_KNOWN_ISSUE: 'search-result-status-known-issue',
  SEARCH_RESULT_STATUS_FEATURE_REQUEST: 'search-result-status-feature-request',
  SEARCH_BAR_RESULT: 'search-bar-result',
  SEARCH_BAR_RESULT_TITLE: 'search-bar-result-title',
  SEARCH_BAR_RESULT_DESCRIPTION: 'search-bar-result-description',
  ROADMAP_FEATURE_REQUEST_SUCCESS_MODAL_CLOSE_BUTTON:
    'roadmap-feature-request-success-modal-close-button',
  ROADMAP_FEATURE_REQUEST_SUCCESS_MODAL_DESCRIPTION:
    'roadmap-feature-request-success-modal-description',
  ROADMAP_FEATURE_REQUEST_SUCCESS_MODAL_TITLE:
    'roadmap-feature-request-success-modal-title',
  ROADMAP_FEATURE_REQUEST_SUCCESS_MODAL_SUBTITLE:
    'roadmap-feature-request-success-modal-subtitle',
  ROADMAP_FEATURE_REQUEST_SUCCESS_MODAL_BODY:
    'roadmap-feature-request-success-modal',
  ROADMAP_FEATURE_REQUEST_MODAL: 'roadmap-feature-request-modal',
  ROADMAP_FEATURE_REQUEST_SUGGESTION_MODAL_BODY:
    'roadmap-feature-request-suggestion-modal',
  ROADMAP_FEATURE_REQUEST_SUGGESTION_MODAL_BACK_BUTTON:
    'roadmap-feature-request-suggestion-modal-back-button',
  EMPTY_SEARCH_RESULTS_HEADER: 'empty-search-results-header',
  EMPTY_SEARCH_RESULTS_MESSAGE: 'empty-search-results-message',
  ARTICLE_ITEM_ETA: 'article-item-eta',
  ARTICLE_RESOLUTION_TABS: 'article-resolution-tabs',
  ARTICLE_RESOLUTION_MENU: 'article-resolution-menu',
  ROADMAP_SORT_MENU: 'roadmap-sort-menu',
  ROADMAP_FILTER_MENU: 'roadmap-filter-menu',
  ARTICLE_STATUS_BANNER: 'article-status-banner',
  EDITOR_X_NOTIFICATION: 'editor-x-notification',
  LABELS_LIST_ICON: 'labels-list-icon',
  LOGIN_TO_GET_UPDATES: 'login-to-get-updates',
  PAGINATION: 'page-strip',
  COMMUNITY_FORUM_ENTRY: 'community-forum-entry',
  COMMUNITY_FORUM_ENTRY_TITLE: 'community-forum-entry-title',
  COMMUNITY_FORUM_ENTRY_DESCRIPTION: 'community-forum-entry-description',
  COMMUNITY_FORUM_ENTRY_BUTTON: 'community-forum-entry-button',
  EMPTY_CATEGORY_STATE: 'empty-category-state',
  EMPTY_CATEGORY_STATE_TITLE: 'empty-category-state-title',
  EMPTY_CATEGORY_STATE_DESCRIPTION: 'empty-category-state-description',
  ROADMAP_SELECT_PRODUCTS: 'roadmap-select-products',
  ROADMAP_SELECT_PRODUCTS_WRAPPER: 'roadmap-select-products-wrapper',
  ROADMAP_FILTER_MENU_WRAPPER: 'roadmap-filter-menu-wrapper',
  ROADMAP_SORT_MENU_WRAPPER: 'roadmap-sort-menu-wrapper',
  CTA_ENGAGEMENT_TEXT_WRAPPER: 'cta-engagement-text-wrapper',
  CTA_ENGAGEMENT_TEXT: 'cta-engagement-text',
  BLOGS_SECTIONS: 'blogs-sections',
  BLOGS_SECTION_HEADER: 'blog-section-header',
  BLOGS_SECTION_SUBHEADER: 'blog-section-subheader',
  BLOGS_SECTION_ITEM: 'blog-section-item',
  BLOGS_SECTION_ITEM_IMAGE: 'blog-section-item-image',
  BLOGS_SECTION_ITEM_TITLE: 'blog-section-item-title',
  BLOGS_SECTION_ITEM_AUTHOR: 'blog-section-item-author',
  BLOGS_SECTION_EXTERNAL_LINK: 'blog-section-external-link',
  BLOGS_SECTION_NON_SUPPORTED_LANGUAGES_TOOLTIP:
    'blog-section-non-supported-languages-tooltip',
  KNOWN_ISSUES_PAGE: 'known-issues-page',
  KNOWN_ISSUES_PAGE_TITLE: 'known-issues-page-title',
  KNOWN_ISSUES_PAGE_DESCRIPTION: 'known-issues-page-description',
  KNOWN_ISSUES_PAGE_SEARCH_AND_FILTER_PANEL: 'known-issues-page-search-panel',
  KNOWN_ISSUES_PAGE_SEARCH: 'known-issues-page-search',
  KNOWN_ISSUES_PAGE_FILTER_BY: 'known-issues-page-filter-by',
  KNOWN_ISSUES_PAGE_SORT_BY: 'known-issues-page-sort-by',
  KNOWN_ISSUES_PAGE_SEARCH_NUMBER_OF_ITEMS: 'known-issues-page-number-of-items',
  KNOWN_ISSUES_PAGE_PAGINATION: 'known-issues-page-pagination',
  KNOWN_ISSUES_PAGE_ITEM: 'known-issues-page-item',
  KNOWN_ISSUES_PAGE_ITEM_TITLE: 'known-issues-page-item-title',
  KNOWN_ISSUES_PAGE_ITEM_PUBLISHED_DATE:
    'known-issues-page-item-published-date',
  KNOWN_ISSUES_PAGE_ITEM_STATUS: 'known-issues-page-item-status',
  KNOWN_ISSUES_PAGE_ITEM_LINK: 'known-issues-page-item-link',
  KNOWN_ISSUES_EMPTY_STATE_TITLE: 'known-issues-empty-state-title',
  KNOWN_ISSUES_EMPTY_STATE_SUBTITLE: 'known-issues-empty-state-subtitle',
  KNOWN_ISSUES_PAGE_FILTER_MODAL: 'known-issues-page-filter-modal',
  KNOWN_ISSUES_PAGE_FILTER_MODAL_FILTER_BY:
    'known-issues-page-filter-modal-filter-by',
  KNOWN_ISSUES_PAGE_FILTER_MODAL_FILTER_BY_MY_ISSUES:
    'known-issues-page-filter-modal-filter-by-my-issues',
  KNOWN_ISSUES_PAGE_FILTER_MODAL_SORT_BY:
    'known-issues-page-filter-modal-sort-by',
  KNOWN_ISSUES_PAGE_FILTERS_COUNT: 'known-issues-page-filters-count',
  KNOWN_ISSUES_PAGE_FILTER_MODAL_APPLY: 'known-issues-page-filter-modal-apply',
  ROADMAP_SEARCH: 'roadmap-search',
  OPT_IN_MODAL: 'opt-in-modal',
  OPT_IN_FAIL_MODAL: 'opt-in-fail-modal',
  OPT_OUT_FAIL_MODAL: 'opt-out-fail-modal',
  FEATURE_REQUEST_ERROR_MODAL: 'feature-request-error-modal',
  STUDIO_ENTRY_POINT_BANNER: 'studio-entry-point-banner',
  STUDIO_ENTRY_POINT_BANNER_TITLE: 'studio-entry-point-banner-title',
  STUDIO_ENTRY_POINT_BANNER_DESCRIPTION:
    'studio-entry-point-banner-description',
  STUDIO_ENTRY_POINT_BANNER_CTA: 'studio-entry-point-banner-cta',
  ARTICLE_LAST_PUBLISHED_DATE: 'article-last-published-date',
  SEARCH_BAR_MODAL: 'search-bar-modal',
  SEARCH_BAR_MODAL_ENTRY_POINT: 'search-bar-modal-entry-point',
  SEARCH_BAR_MODAL_BODY: 'search-bar-modal-body',
  SEARCH_BAR_MODAL_INPUT: 'search-bar-modal-input',
  SEARCH_BAR_MODAL_CTA: 'search-bar-modal-cta',
  SEARCH_BAR_MODAL_BADGE: 'search-bar-modal-badge',
  REQUEST_FEATURE_USE_DESKTOP_MODAL: 'request-feature-use-desktop-modal',
  REQUEST_FEATURE_USE_DESKTOP_MODAL_BUTTON:
    'request-feature-use-desktop-modal-button',
  ROADMAP_FEATURE_REQUEST_SUCCESS_MODAL:
    'roadmap-feature-request-success-modal',
  SELECT_EXISTING_FEATURE_SUGGESTED_FEATURE:
    'select-existing-feature-suggested-feature',
  SELECT_EXISTING_FEATURE_BACK_BUTTON: 'select-existing-feature-back-button',
  SELECT_EXISTING_FEATURE_MODAL_TITLE: 'select-existing-feature-modal-title',
  SUGGESTED_EXISTING_FEATURE_OPT_IN_BADGE:
    'suggested-existing-feature-opt-in-badge',
  SUGGESTED_EXISTING_FEATURE_ITEM_TITLE:
    'suggested-existing-feature-item-title',
  SUGGESTED_EXISTING_FEATURE_ITEM_HIGH_MATCH:
    'suggested-existing-feature-item-high-match',
  ROADMAP_FEATURE_REQUEST_ERROR_MODAL_TITLE:
    'roadmap-feature-request-error-modal-title',
  FEATURE_REQUEST_LOADING_MODAL: 'feature-request-loading-modal',
  FEATURE_REQUEST_LOADING_MODAL_TEXT: 'feature-request-loading-modal-text',
  GEN_ANSWER_HEADING: 'gen-answer-heading',
  GEN_ANSWER_QUESTION: 'gen-answer-question',
  GEN_ANSWER_ANSWER: 'gen-answer-answer',
  GEN_ANSWER_SOURCE_ARTICLE: 'gen-answer-source-article',
  GEN_ANSWER_VOTING: 'gen-answer-voting',
  GEN_ANSWER_HELPFUL: 'gen-answer-helpful',
  GEN_ANSWER_NOT_HELPFUL: 'gen-answer-not-helpful',
  GEN_ANSWER_ADDITIONAL_FEEDBACK: 'gen-answer-additional-feedback',
  GEN_ANSWER_UNHELPFUL_REASONS: 'gen-answer-unhelpful-reasons',
  GEN_ANSWER_UNHELPFUL_BADGE: 'gen-answer-unhelpful-badge',
  GEN_ANSWER_FOOTER: 'gen-answer-footer',
  GEN_ANSWER_WRAPPER: 'gen-answer-wrapper',
  BETA_GEN_ANSWER: 'beta-gen-answer',
  GEN_ANSWER_PROHIBITED: 'gen-answer-prohibited',
  GEN_ANSWER_BASED_ON_ARTICLES: 'based-on-articles',
  GEN_ANSWER_LEGAL_INFO: 'gen-answer-legal-info',
  GEN_ANSWER_UNHELPFUL_REASONS_FLOATING_NOTIFICATION:
    'gen-answer-unhelpful-reasons-floating-notification',
  GEN_ANSWER_LOADER: 'gen-answer-loader',
  GEN_ANSWER_ERROR_VIEW: 'gen-answer-error-view',
  GEN_ANSWER_ERROR_VIEW_CTA: 'gen-answer-error-view-cta',
  ARTICLE_PAGE_SIDEBAR_ON_LEFT_SIDE: 'article-page-sidebar-on-left-side',
  STUDIO_BANNER: 'studio-banner',
  VISIBLE_FEEDBACK_OPTION: 'visible-feedback-option',
  VISIBLE_FEEDBACK_OPTION_SELECTED: 'visible-feedback-option-selected',
  VISIBLE_FEEDBACK_THANK_YOU_MESSAGE: 'visible-feedback-thank-you-message',
  SEND_FEEDBACK_BUTTON: 'send-feedback-button',
  ADDITIONAL_FEEDBACK_TEXT: 'additional-feedback-text',
  ADDITIONAL_FEEDBACK_TITLE: 'additional-feedback-title',
  CONTACT_SUPPORT: 'contact-support',
  CONTACT_SUPPORT_BODY: 'contact-support-body',
  HC_LOADER: 'hc-loader',
  ERROR_PAGE_REFRESH_BUTTON: 'error-page-refresh-button',
};
