import React, { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import {
  Box,
  Dropdown,
  DropdownLayoutValueOption,
  FormField,
} from '@wix/design-system';
import css from './index.module.scss';
import { ArticleResolution, ArticleSortType, TsUtils } from '@wix/answers-api';
import {
  FILTER_RESOLUTIONS,
  RoadmapFeatureResolution,
  RoadmapFilter,
  RoadmapETAorVotedFilter,
  SortAndFilterBiMetaData,
} from '../../../types';
import { DATA_HOOKS } from '../../../dataHooks';
import { ALL_PRODUCTS_LABEL_ID, EXPERIMENTS, MAX_MOBILE_WIDTH } from '../../../constants';
import { ALL_PRODUCTS_LABEL } from '../../../pages/roadmap';
import RoadmapMobileSortAndFilterMenu from './RoadmapMobileSortAndFilterMenu';
import { Context } from '../../../context';
import { isMobile } from '../../../utils/userAgent';
import { RoadmapSelectProducts } from './RoadmapSelectProducts';
import { RoadmapTagSelectedProducts } from './RoadmapTagsSelectedProducts';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useExperiments } from '@wix/fe-essentials-standalone';

export const isSmallScreenSize = (width: number): boolean =>
  width > MAX_MOBILE_WIDTH && width < 1200;

export type RoadmapSortAndFilterProps = {
  roadmapFilter: RoadmapFilter;
  onChangeFilter: (filter: RoadmapFilter) => void;
  productLabels: { id: string; name: string }[];
  applyValue: (
    filter: RoadmapETAorVotedFilter,
    resolution?: RoadmapFeatureResolution
  ) => void;
  isScrolled: boolean;
  onChangeTriggerBiMetaData: (
    triggerBiMetaData: SortAndFilterBiMetaData
  ) => void;
};
export const ALL_SORT_OPTION = [
  ArticleSortType.LAST_PUBLISH_DATE_DESC,
  ArticleSortType.VOTES_DESC,
];

export const allFilterOptions = TsUtils.enumToArray(
  RoadmapETAorVotedFilter
) as RoadmapETAorVotedFilter[];

export const RoadmapSortAndFilter: FunctionComponent<
  RoadmapSortAndFilterProps
> = ({
  productLabels,
  roadmapFilter,
  onChangeFilter,
  applyValue,
  isScrolled,
  onChangeTriggerBiMetaData,
}) => {
  const { t } = useTranslation();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const resolutions = ['all', ...FILTER_RESOLUTIONS];
  const context = useContext(Context);
  const isMobileView = isMobile(context);
  const windowSize = useWindowSize();
  const { experiments } = useExperiments({ readOnly: true });

  React.useEffect(() => {
    if (windowSize.width) {
      setIsSmallScreen(isSmallScreenSize(windowSize.width));
    }
  }, [windowSize.width]);

  const selectedOptionsLabels = React.useMemo(
    () =>
      (roadmapFilter.labels.length === 1 &&
        roadmapFilter.labels[0].id === ALL_PRODUCTS_LABEL_ID) ||
      !roadmapFilter.labels
        ? []
        : roadmapFilter.labels,
    [roadmapFilter.labels]
  );

  const onDeselectProduct = React.useCallback(
    (id: string | number) => {
      if (id === ALL_PRODUCTS_LABEL_ID) {
        onChangeFilter({ ...roadmapFilter, labels: [ALL_PRODUCTS_LABEL] });
      } else {
        onChangeFilter({
          ...roadmapFilter,
          labels: roadmapFilter.labels.filter((label) => label.id !== id),
        });
      }
    },
    [onChangeFilter, selectedOptionsLabels]
  );

  const FilterOptions: DropdownLayoutValueOption[] = allFilterOptions.map(
    (filterOption) => ({
      id: filterOption,
      value:
        experiments.enabled(EXPERIMENTS.SPECS.AUTO_ENROLLMENT) &&
        filterOption === RoadmapETAorVotedFilter.FOLLOWING &&
        context.isWixStudioUser
          ? t(`roadmap.filter-option.${filterOption}.auto-enrollment`)
          : t(`roadmap.filter-option.${filterOption}`),
    })
  );

  const onChangeFilterType = (
    option: DropdownLayoutValueOption,
    sameOptionWasPicked: boolean
  ) => {
    if (!sameOptionWasPicked) {
      onChangeFilter({
        ...roadmapFilter,
        ETAorVoted: option.id as RoadmapETAorVotedFilter,
      });
    }
  };

  const resolutionOptions: DropdownLayoutValueOption[] = resolutions.map(
    (res) => ({
      id: res,
      value: t(`roadmap.article-list.resolutions.${res}`),
    })
  );

  const onChangeStatus = (
    option: DropdownLayoutValueOption,
    sameOptionWasPicked: boolean
  ) => {
    if (!sameOptionWasPicked) {
      onChangeFilter({
        ...roadmapFilter,
        resolution: option.id as ArticleResolution,
      });
    }
  };

  const onClear = () => {
    onDeselectProduct(ALL_PRODUCTS_LABEL_ID);
  };

  const hideForDesktop = !isSmallScreen && !isMobileView;

  return (
    <Box
      direction="vertical"
      align="center"
      className={`${css.roadmapSortAndFilter} ${
        isMobileView ? css.mobile : ''
      } ${isScrolled ? css.scrolled : ''}`}
    >
      <Box
        align="center"
        className={css.selectSection}
        gap={'SP3'}
        width={'100%'}
      >
        {!isMobileView && (
          <>
            <RoadmapSelectProducts
              roadmapFilter={roadmapFilter}
              onChangeFilter={onChangeFilter}
              productLabels={productLabels}
              onChangeTriggerBiMetaData={onChangeTriggerBiMetaData}
            />
            <FormField label={t('roadmap.resolution-header')}>
              <Dropdown
                selectedId={roadmapFilter.resolution}
                dataHook={DATA_HOOKS.ARTICLE_RESOLUTION_MENU}
                onSelect={onChangeStatus}
                className={css.roadmapResolution}
                border="round"
                placeholder={''}
                options={resolutionOptions}
                textOverflow="ellipsis"
              />
            </FormField>
            <FormField
              dataHook={DATA_HOOKS.ROADMAP_FILTER_MENU_WRAPPER}
              label={t('roadmap.filter-header')}
            >
              <Dropdown
                selectedId={roadmapFilter.ETAorVoted}
                dataHook={DATA_HOOKS.ROADMAP_FILTER_MENU}
                onSelect={onChangeFilterType}
                className={css.roadmapFilterMenu}
                border="round"
                placeholder={''}
                options={FilterOptions}
                textOverflow="ellipsis"
              />
            </FormField>
          </>
        )}
        {!hideForDesktop && (
          <Box
            className={`${css.mobileSortAndFilterMenuWrapper} ${
              isMobileView ? css.mobile : ''
            }`}
          >
            <RoadmapMobileSortAndFilterMenu
              roadmapFilter={roadmapFilter}
              applyValue={applyValue}
              productLabels={productLabels}
              onChangeFilter={onChangeFilter}
              onChangeTriggerBiMetaData={onChangeTriggerBiMetaData}
            />
          </Box>
        )}
      </Box>
      {!isMobileView && selectedOptionsLabels.length > 0 ? (
        <RoadmapTagSelectedProducts
          selectedLabels={selectedOptionsLabels}
          onClear={onClear}
          onTagRemove={(id) => onDeselectProduct(id)}
        />
      ) : null}
    </Box>
  );
};
