import { useRouter } from 'next/router';
import { ArticleResolution, ArticleType } from '@wix/answers-api';
import { Box, Heading, Text } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { TimerSmall } from '@wix/wix-ui-icons-common';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { ConsumerSource, Anchors } from '@wix/cc-user-facing-common-components';
import { FunctionComponent, useContext } from 'react';
import { DATA_HOOKS } from '../../../dataHooks';
import { RoadmapResolutionEta } from '../../RoadmapResolutionEta';
import { ArticleStatusBanner } from './ArticleStatusBanner';
import { ArticlePublishedDate } from './ArticlePublishedDate';
import { Step } from '../../../pages/article/[articleTitle]';
import { BI, LOCALES } from '../../../constants';
import { useBI } from '../../../hooks/useBI';
import { mapArticleTypeToItemType } from '../../../utils/bi';
import { Context } from '../../../context';
import css from './index.module.scss';

const ANCHORS_SUPPORTED_LOCALES = [LOCALES.EN, LOCALES.PT, LOCALES.ES];

export type ArticleHeaderProps = {
  type: ArticleType;
  title: string;
  creationDate: number | Long;
  resolution?: ArticleResolution;
  resolutionEta?: Long;
  isRoadmapArticle?: boolean;
  readingTime?: number;
  onClickStatus?: (resolution: ArticleResolution) => void;
  steps: Step[];
};

export const ArticleHeader: FunctionComponent<ArticleHeaderProps> = ({
  type,
  resolution,
  resolutionEta,
  title,
  isRoadmapArticle,
  readingTime,
  creationDate,
  onClickStatus,
  steps
}: ArticleHeaderProps) => {
  const { locale = '' } = useRouter();
  const { t } = useTranslation();
  const { sendBIEvent } = useBI();
  const { pageTemplateData: { itemId } } = useContext(Context);

  const onAnchorClick = async (text: string, order: string) => {
    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.ARTICLE,
        kb_lang: locale as string,
        item_id: itemId,
        item_type: mapArticleTypeToItemType(type),
        clicked_item_type: BI.CLICKED_ITEM_TYPES.INTERNAL_ANCHOR,
        clicked_text: text,
        clicked_item_order: order,
        is_page_content_click: true,
      })
    );
  };

  const showReadingTime =
    type === ArticleType.ARTICLE && readingTime && readingTime > 0;

  return (
    <>
      <Heading size='extraLarge' className={css.articleTitle}>
        {title}
      </Heading>

      {type === ArticleType.KNOWN_ISSUE ?
        <ArticlePublishedDate locale={locale as string} creationDate={creationDate} />
        : null}

      {isRoadmapArticle && resolution ? (
        <div className={css.articleRoadmapTags}>
          <RoadmapResolutionEta
            wrapEtaWithTag={true}
            resolution={resolution}
            resolutionEta={resolutionEta}
            onClickStatus={onClickStatus}
          />
        </div>
      ) : (
        [ArticleType.FEATURE_REQUEST, ArticleType.KNOWN_ISSUE].includes(type) &&
        <ArticleStatusBanner
          articleResolution={resolution}
          articleType={type}
        />
      )}
      <Box gap='SP4' direction='vertical' paddingTop='SP3'>
        {showReadingTime ? (
          <Box className={css.timeToReadWrapper}>
            <TimerSmall color={'#868AA5'} />
            <Text
              dataHook={DATA_HOOKS.ARTICLE_READING_TIME}
              size='small'
              color='D40'
              className={css.timeToRead}
            >
              {t('informative-article-time-to-read', { time: readingTime })}
            </Text>
          </Box>
        ) : null}
        {
          ANCHORS_SUPPORTED_LOCALES.includes(locale) &&
          steps.length > 0 &&
          <Box className={css.anchorsWrapper}>
            <Anchors steps={steps} onClick={onAnchorClick} consumerSource={ConsumerSource.HELP_CENTER} locale={locale as string} />
          </Box>
        }
      </Box>
    </>
  );
};
