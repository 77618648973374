/* eslint-disable max-lines */
import {
  Article,
  Category,
  ArticleType,
  CategoryArticles,
  ArticleResolution,
  Label as AnsLabel,
  ArticleSortType,
  User,
  Translation,
  ArticleStatus,
  ArticleAlternativeContentNumber,
  ExtendedArticle,
} from '@wix/answers-api';
import type { NextApiRequest, NextApiResponse, Redirect } from 'next';
import { BootstrapContext } from '@wix/wix-bootstrap-api';
import { BootstrapBi } from '@wix/wix-bootstrap-bi';
import type { AspectStore } from '@wix/wix-aspects';
import { Redis } from 'ioredis';
import { ParsedUrlQuery } from 'querystring';
import { HeaderFooterProvider } from '@wix/header-footer-provider';
import { ApiGwClient } from '@wix/wix-bootstrap-api-gw-client';
import type {
  Article as SearchProxyArticle,
  ArticleRelations,
  GetArticleByUriDto,
  SearchRequest,
  SearchResult,
} from '@wix/ambassador-answers-search-proxy/types';
import { ExperimentsBag } from '@wix/wix-experiments';
import { sortAndFilterParams } from '@wix/bi-logger-new-help-center/v2/types';
import { Moment } from 'moment';
export interface GetBrandHelpcenterDataResponse {
  categories: Category[];
  categoriesTopArticles: {
    categoryId: string;
    articles: {
      id: string;
      title: string;
      uri: string;
      articleType: ArticleType;
    }[];
  }[];
  articles: Article[];
  sessionUser?: User;
}

export type ClickedItemType =
  | 'see_all'
  | 'category_selection'
  | 'sub_category_selection';

export type BootstrapContextServerNextjs = BootstrapContext & {
  apiGwClient: ApiGwClient;
} & HeaderFooterProvider & { bi: BootstrapBi.BootstrapBiLoggerFactory };

export type AppApiResponse<T = unknown> = NextApiResponse<T> & {
  locals: {
    appContext: BootstrapContextServerNextjs;
    redisClient: Redis;
    mondayToken: string;
    knowledgeAgentDistanceLimit: number;
  };
};

export type AppApiRequest = NextApiRequest & {
  aspects: AspectStore;
  apiGatewayClient: ApiGwClient;
};

type GetWixServerSidePropsResult = {
  props?: Record<string, any>;
  redirect?: Redirect;
  notFound?: true;
  experimentsPageProps?: ExperimentsBag;
};

export type GetWixServerSidePropsFunction = ({
  req,
  res,
  locale,
  query,
  experiments,
  isLoggedInUser,
  isWixEmployee,
  err,
}: {
  req: AppApiRequest;
  res: AppApiResponse;
  locale: string;
  query: ParsedUrlQuery;
  experiments: ExperimentsBag;
  isLoggedInUser: boolean;
  isWixEmployee: boolean;
  err?:
  | (Error & {
    statusCode?: number;
  })
  | null;
}) => Promise<GetWixServerSidePropsResult>;

export type HelpCenterRpcService = {
  getArticle: (dto: {
    id: string;
    locale: string;
    tenant: { id: string };
  }) => Promise<Article>;
  getExtendedArticle: (dto: {
    id: string,
    locale: string;
    tenant: { id: string };
  }) => Promise<ExtendedArticle>;
  getArticleByUri: (dto: {
    uri: string;
    locale: string;
    withRelatedArticles: boolean;
    tenant: { id: string };
  }) => Promise<GetArticleResponse>;
  getTenantInfo: (host: string, locale: string) => Promise<any>;
  searchArticles: (dto: {
    locale: string;
    tenant: { id: string };
    text?: string;
    categoryIds?: string[];
    types?: number[];
    summarize?: boolean;
    sortType?: number;
    pageSize?: number;
    resolutions?: ArticleResolution[];
    hasAnyOfLabelIds?: string[];
    page?: number;
    ids?: string[];
  }) => Promise<ArticleSearchResult<Article>>;
  adminSearchArticles: (dto: {
    locale: string;
    tenant: { id: string };
    text?: string;
    categoryIds?: string[];
    types?: number[];
    summarize?: boolean;
    sortType?: number;
    pageSize?: number;
    resolutions?: ArticleResolution[];
    hasAnyOfLabelIds?: string[];
    page?: number;
    fromResolutionEta?: string;
    toResolutionEta?: string;
    hasResolutionEta?: boolean;
    ids?: string[];
    excludedCategoryIds?: string[];
    statuses?: ArticleStatus[];
  }) => Promise<ArticleSearchResult<Article>>;
  getHelpCenterData: (
    tenantId: string,
    locale: string
  ) => Promise<GetBrandHelpcenterDataResponse>;
  getCategoryByUri: (dto: {
    uri: string;
    locale: string;
    tenant: { id: string };
  }) => Promise<Category>;
  validateBrandPermission: (
    tenantId: string,
    brandId: string
  ) => Promise<void | never>;
  getArticlesFollowedByUser: (dto: {
    tenant: { id: string };
    locale: string;
    page: number;
    pageSize: number;
  }) => Promise<Article[]>;
  getCategoriesViewableTranslations: (dto: {
    tenant: { id: string };
    categoryIds: string[];
    brandId: string;
  }) => Promise<{ translations: Translation[]; id: string }[]>;
};

export type AnswersSearchProxyService = {
  search: (request: SearchRequest) => Promise<SearchResult>;
  getArticleByUri: (dto: GetArticleByUriDto) => Promise<ArticleRelations>;
};

export type ExportRpcService = {
  getLabels: (tenantId: string) => Promise<AnsLabel[]>;
};

export type AiWrapperRpcService = {
  GetRelatedDocs: (dto: {
    query: string;
    numOfDocs: number;
    product: string;
  }) => Promise<Article[]>;
};

export type GetArticleResponse = {
  article: Article | SearchProxyArticle;
  relatedArticles: Article[] | SearchProxyArticle[];
};

export type FeatureEnrollment = {
  featureId: string;
  experimentId?: number | null;
};

export type GenAiArticleLink = {
  articleId: string;
  articleURL: string;
  articleName: string;
  type: ArticleType;
  docType: ArticleSearchResultDocumentType;
  distance?: number;
};

export type ArticleSearchResultItem = {
  id: string;
  title: string;
  uri: string;
  url: string;
  description: string;
  type: ArticleType;
  categoryId: string;
  labels: AnsLabel[];
  resolution: ArticleResolution | null;
  resolutionEta?: number | null;
  readingTimeInMinutes?: number;
  creationDate: number | Long;
  featureEnrollment?: FeatureEnrollment;
  allowEnrollment?: boolean;
  distance?: number;
  baseUrl?: string;
  docType: ArticleSearchResultDocumentType;
  content?: string;
};

export enum ArticleSearchResultDocumentType {
  ANSWERS_ARTICLE = 'ANSWERS_ARTICLE',
  WIX_DOCS_ARTICLE = 'WIX_DOCS_ARTICLE',
  UNKNOWN_RELATED_DOCUMENT_TYPE = 'UNKNOWN_RELATED_DOCUMENT_TYPE',
}

export type ArticleSearchResult<T = ArticleSearchResultItem> = {
  items: T[];
  itemsCount: number;
  searchMethod?: string;
  searchVersion?: string;
  topFiveArticles?: T[];
};

export type Label = {
  id: string;
  name: string;
};

export interface IAnswersAPI {
  getArticle: (
    uri: string,
    locale: string,
    isPreview: boolean,
    alternativeVersionNumber?: ArticleAlternativeContentNumber,
  ) => Promise<GetArticleResponse>;
  getArticleById: (id: string, locale: string) => Promise<Article>;
  getCategoryByUri: (uri: string, locale: string) => Promise<Category>;
  getArticles: (
    categoryIds: string[],
    locale: string,
    useAnswersSearch?: boolean
  ) => Promise<Article[]>;
  searchArticles: (
    locale: string,
    filter: ArticleFilter,
    useVespa?: boolean
  ) => Promise<ArticleSearchResult>;
  adminSearchArticles: (
    locale: string,
    filter: ArticleFilter,
    useVespa?: boolean
  ) => Promise<ArticleSearchResult>;
  getHelpCenterData: (locale: string) => Promise<HelpCenterData>;
  getPageSeo: (locale: string, pageType: PageType) => Promise<PageSeo>;
  validateBrandPermission: (
    tenantId: string,
    brandId: string
  ) => Promise<void | never>;
  getTenantInfo: (host: string, locale: string) => Promise<any>;
  getLabels: () => Promise<AnsLabel[]>;
  getArticlesFollowedByUser: (dto: {
    locale: string;
    page: number;
    pageSize: number;
  }) => Promise<Article[]>;
  getCategoryTranslations: (categoryId: string) => Promise<Translation[]>;
}

export type ArticleFilter = {
  text: string;
  resolutions?: ArticleResolution[];
  pageSize?: number;
  page?: number;
  types?: ArticleType[];
  hasAnyOfLabelIds?: string[];
  sortType?: ArticleSortType;
  fromResolutionEta?: string;
  toResolutionEta?: string;
  hasResolutionEta?: boolean;
  excludedCategoryIds?: string[];
  ids?: string[];
  statuses?: ArticleStatus[];
};

export enum PageType {
  Article,
  Homepage,
  Category,
  SearchResults,
  Ticket,
  RoadMap,
  Profile,
  Contact,
  KnownIssues,
}

type Robots = {
  noArchive: boolean;
  noFollow: boolean;
  noIndex: boolean;
};

export type PageSeo = {
  description: string;
  locale: string;
  title: string;
  robots: Robots;
  imageUrl: string;
};

export enum HttpStatusCode {
  OK = 200,
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,
  TIMEOUT_ERROR = 504,
}

export enum UploadType {
  IMAGE = 'image',
  FILE = 'file',
}

export const ALL_RESOLUTIONS = [
  ArticleResolution.COLLECTING_VOTES,
  ArticleResolution.PLANNED,
  ArticleResolution.IN_DEVELOPMENT,
  ArticleResolution.COMING_SOON,
  ArticleResolution.PENDING_RELEASE,
  ArticleResolution.RELEASED,
];

export const FILTER_RESOLUTIONS = [
  ArticleResolution.COLLECTING_VOTES,
  ArticleResolution.PLANNED,
  ArticleResolution.IN_DEVELOPMENT,
  ArticleResolution.COMING_SOON,
  ArticleResolution.RELEASED,
];

export const KNOWN_ISSUES_RELEVANT_RESOLUTIONS = [
  ArticleResolution.INVESTIGATING,
  ArticleResolution.WORKING_ON_IT,
  ArticleResolution.THIRD_PARTY_BUG,
  ArticleResolution.RESOLVED,
];

export enum RoadmapETAorVotedFilter {
  ALL = 10,
  FOLLOWING = 20,
  ETA_THIS_QUARTER = 30,
  ETA_NEXT_QUARTER = 40,
}

export type RoadmapFilter = {
  labels: Label[];
  ETAorVoted: RoadmapETAorVotedFilter;
  resolution: RoadmapFeatureResolution;
};

export type RoadmapFeatureResolution = ArticleResolution | 'all';
export interface CustomWindow extends Window {
  HeaderAPI: HeaderAPI;
  YT: {
    Player: any;
    ready: (func: any) => void;
  };
}
export interface CustomDocument extends Document {
  webkitFullscreenElement: Element | null;
  mozFullScreenElement: Element | null;
  msFullscreenElement: Element | null;
}

export type HelpCenterData = {
  articles: Article[];
  categories: Category[];
  categoriesTopArticles: CategoryArticles[];
};

interface HeaderAPI {
  updateLinks: (params: UpdateLinksParams) => void;
}

interface UpdateLinksParams {
  postLogin?: string;
  postSignUp?: string;
  postSignOut?: string;
}

export interface DocMetadata {
  metadata?: Record<string, any> | null;
}

export enum YouTubeTypeEvent {
  PLAY_VIDEO = 'play_video',
  PAUSE_VIDEO = 'pause_video',
  OPEN_VIDEO_FULL_SCREEN = 'open_video_full_screen',
  CLOSE_VIDEO_FULL_SCREEN = 'close_video_full_screen',
  VIDEO_SEEK_BAR = 'video_seek_bar',
}

export type KbBlog = {
  title?: string;
  author: {
    name?: string | null;
    ownerUrl?: string;
  };
  blogImageSrc?: string;
  url?: string;
  id?: string;
  lastPublishedDate?: Date;
};

export enum VoteType {
  FOLLOW = 'follow',
  VOTE = 'vote',
  ENROLLMENT = 'enrollment',
}

export type SortAndFilterBiMetaData = Pick<
  sortAndFilterParams,
  'filter_by' | 'sort_by' | 'items_left' | 'search_term_filter'
>;

export enum SearchSource {
  DROPDOWN_SEARCH = 'dropdown_search',
  SEARCH_RESULTS_PAGE = 'search_results_page',
}

export enum AB_TESTING_LABELS {
  A = 'A',
  B = 'B',
}

export type SearchResults = {
  [key: string]: {
    items: SearchResultItem[];
    itemsCount: number;
  };
};

export type SearchResultsByType = {
  items: ArticleSearchResultItem[];
  itemsCount: number;
};

export type ArticleSourceType = ArticleType;

export type SearchResultItem = {
  id?: string;
  title: string;
  description: string;
  url: string;
  uri?: string;
  type: ArticleSourceType;
};

export interface SessionBI {
  lastSessionDate: Moment;
  selfServiceSessionId: string | null;
}

export enum SessionBIType {
  VISITOR = 'VISITOR',
  USER = 'USER',
}

export interface LogBI {
  evid: number;
  endpoint: string;
  src: number;
  params: { [key: string]: any };
}

export enum ROADMAP_SUCCESS_TYPE {
  OPT_IN = 'opt-in',
  SUBMIT = 'submit',
  SELECTING = 'selecting',
}

export enum ROADMAP_ERROR_MESSAGE_TYPE {
  TOO_MANY_REQUESTS = 'too-many-requests',
  TECHNICAL_ERROR = 'technical-error',
}

export type ArticleWithDistanceAndRelevancy = Article & {
  distance: number;
  relevancy: number;
};

export type ArticlesAndFeatureRequests = {
  article?: ArticleWithDistanceAndRelevancy;
  featureRequests: ArticleWithDistanceAndRelevancy[];
};

export enum RequestAFeatureModalStep {
  REQUEST_A_FEATURE = 'request-a-feature',
  LOADING_EXISTING_FEATURES = 'loading-existing-features',
  SELECT_EXISTING_FEATURE = 'select-existing-feature',
  FEATURE_REQUEST_ALREADY_EXISTS = 'feature-request-already-exists',
  OPT_IN = 'opt-in', // We decided to not allow users to opt in, also we need to think about some missing design & product specs - what if the selected feature is alredy optedin?
  LOADING_SUBMITTING = 'loading-submitting',
  SUCCESS_SELECTED = 'success-selected',
  SUCCESS_SUBMITTED = 'success-submitted',
}

export enum VoteOptions {
  YES = 'yes',
  UNHELPFUL = 'unhelpful',
  UNSATISFIED = 'unsatisfied',
  OTHER = 'other',
}

export enum RequestAFeatureModalError {
  UNABLE_TO_SELECT_FEATURE = 'unable-to-select-feature',
  UNABLE_TO_SEND_REQUEST = 'unable-to-send-request',
  TOO_MANY_REQUESTS = 'too-many-requests',
}

export const PAGES = {
  ARTICLE: PageType.Article,
  CATEGORY: PageType.Category,
  SEARCH_RESULTS: PageType.SearchResults,
  HOMEPAGE: PageType.Homepage,
  TICKET: PageType.Ticket,
  HOME: PageType.Homepage,
  ROADMAP: PageType.RoadMap,
  PROFILE: PageType.Profile,
  CONTACT: PageType.Contact,
  KNOWN_ISSUES: PageType.KnownIssues,
};

export type GenerateAnswerResult = {
  term: string,
  answer: string,
  usedArticles: string[],
  isProhibited: boolean
};

