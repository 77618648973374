import { FunctionComponent } from 'react';
import { ChevronRightIcon } from '../../../icons/ChevronRightIcon';
import { Box, Text } from '@wix/design-system';
import { BreadcrumbItem } from '..';
import { DATA_HOOKS } from '../../../dataHooks';
import { InnerLink } from '../../InnerLink';
import { BreadcrumbsStructuredData } from './BreadcrumbsStructuredData';
import css from './index.module.scss';
import { TOOLTIP_ENTER_DELAY } from '../../../constants';

export type BreadcrumbsProps = {
  breadcrumbItem: BreadcrumbItem;
  isLastItem: boolean;
  hideChevron?: boolean;
  shallow?: boolean;
  index: number;
};

export const Breadcrumb: FunctionComponent<BreadcrumbsProps> = ({
  breadcrumbItem,
  isLastItem,
  hideChevron,
  shallow,
  index,
}: BreadcrumbsProps) => {
  const { onClick, label } = breadcrumbItem;

  const BreadcrumbText: FunctionComponent<{ asLink: boolean }> = ({
    asLink,
  }) => (
    <Text
      tabIndex={!asLink ? 0 : undefined}
      tooltipProps={{
        size: 'small',
        maxWidth: '248px',
        placement: 'top',
        enterDelay: TOOLTIP_ENTER_DELAY
      }}
      className={`${css.breadcrumbItemText} ${asLink ? css.breadcrumbItemTextLink : ''
        } ${isLastItem ? css.lastItem : ''}`}
      dataHook={`${DATA_HOOKS.BREADCRUMB}-text`}
      size="small"
      weight={isLastItem ? 'bold' : 'thin'}
      itemProp={'name'}
      ellipsis
    >
      {label}
    </Text>
  );

  return (
    <Box align="center" verticalAlign="middle" className={css.breadcrumbItem}>
      {!hideChevron && (
        <Box
          className={css.breadcrumbItemChevron}
          dataHook={DATA_HOOKS.BREADCRUMB_ITEM_SEPARATOR}
        >
          <ChevronRightIcon />
        </Box>
      )}
      <BreadcrumbsStructuredData index={index}>
        {breadcrumbItem.uri ?
          <>
            <InnerLink
              href={breadcrumbItem.uri}
              className={css.collapseItem}
              dataHook={DATA_HOOKS.BREADCRUMB}
              onClick={onClick}
              withEnterHandler
              itemProp={'item'}
              shallow={shallow}
            >
              <BreadcrumbText asLink={true} />
            </InnerLink>
          </> : <BreadcrumbText asLink={false} />
        }
      </BreadcrumbsStructuredData>

    </Box>
  );
};
