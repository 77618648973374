import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { usePathname } from 'next/navigation';
import { CustomModalLayout, Modal, Heading, Input } from '@wix/design-system';
import {
  SearchSparkles as SearchSparklesIcon,
  Search as SearchIcon
} from '@wix/wix-ui-icons-common';
import { useTranslation } from '@wix/wix-i18n-config';
import { noop } from '@wix/answers-lib';
import { v4 as uuidv4 } from 'uuid';
import { RenderHTML } from '../../RenderHTML';
import { Context } from '../../../context';
import { SearchBarModalBody } from './SearchBarModalBody';
import { pushUrl } from '../../InnerLink';
import { DATA_HOOKS } from '../../../dataHooks';
import { useExperiments } from '@wix/fe-essentials-standalone';
import { EXPERIMENTS, LOCALES, SESSION_STORAGE } from '../../../constants';
import { PageType } from '../../../types';
import { useRouter } from 'next/router';
import css from './index.module.scss';

type SearchBarModalProps = {
  pageType: PageType;
};

export const SearchBarModal: FunctionComponent<SearchBarModalProps> = ({
  pageType,
}) => {
  const { locale } = useRouter();
  const pathname = usePathname();
  const [query, setQuery] = useState('');
  const { setSearchSessionId, showSearchModal, setShowSearchModal } = useContext(Context);

  const {
    isLoggedInUser,
    pageTemplateData: { itemId, itemType },
    user,
    isMobile,
    isTablet
  } = useContext(Context);

  const { t } = useTranslation();
  const { experiments } = useExperiments({ readOnly: true });

  const isGenAnswerEnabled =
    experiments.enabled(EXPERIMENTS.SPECS.NEXT_GEN_SEARCH) &&
    locale === LOCALES.EN && !isMobile && !isTablet;

  const openModal = () => {
    if (!showSearchModal) {
      setShowSearchModal(true);
    }
  };

  const closeModal = () => {
    setShowSearchModal(false);
    setQuery('');
  };

  const goToSearchResultsPage = async (overrideQuery?: string) => {
    closeModal();
    sessionStorage.setItem(
      SESSION_STORAGE.KEYS.USER_SEARCHED,
      JSON.stringify({
        itemId,
        itemType,
        pageType,
        inputType:
          overrideQuery && overrideQuery?.length > 0
            ? 'suggested question'
            : '',
      })
    );
    setSearchSessionId(uuidv4());
    await pushUrl(
      `/${locale}/search?term=${overrideQuery && overrideQuery?.length > 0 ? overrideQuery : encodeURIComponent(query)
      }`
    );
  };

  useEffect(() => {
    setShowSearchModal(false);
  }, [pathname]);

  return (
    <div className={css.searchBarModal} onClick={openModal}>
      <Input
        className={css.searchBarModalCTA}
        placeholder={t('subheader-searchbar.placeholder')}
        onChange={noop}
        value={''}
        dataHook={DATA_HOOKS.SEARCH_BAR_MODAL_ENTRY_POINT}
        disableEditing={true}
        size='large'
        prefix={
          <Input.IconAffix>
            {isGenAnswerEnabled && !isMobile ? <SearchSparklesIcon /> : <SearchIcon />}
          </Input.IconAffix>
        }
      />
      <Modal
        dataHook={DATA_HOOKS.SEARCH_BAR_MODAL}
        isOpen={showSearchModal}
        onRequestClose={closeModal}
        scrollable={false}
        screen={isMobile ? 'mobile' : 'desktop'}
      >
        <CustomModalLayout
          dataHook={DATA_HOOKS.SEARCH_BAR_MODAL_BODY}
          onCloseButtonClick={closeModal}
          showHeaderDivider={!isMobile}
          width={572}
          overflowY="none"
          className={css.searchBarModalLayout}
          title={
            <Heading
              size={isMobile ? 'large' : 'medium'}
              className={css.searchBarModalTitle}
            >
              <RenderHTML
                html={
                  (isLoggedInUser && user?.firstName)
                    ? t('search-bar-modal.title-with-user', {
                      firstName: user?.firstName,
                    })
                    : t('search-bar-modal.title-without-user')
                }
              />
            </Heading>
          }
          content={
            <SearchBarModalBody
              goToSearchResultsPage={goToSearchResultsPage}
              query={query}
              setQuery={setQuery}
            />
          }
        />
      </Modal>
    </div>
  );
};
