import {
  ArticleResolution,
  ArticleSortType,
  FeatureRequestResolutions,
  Label,
} from '@wix/answers-api';
import Long from 'long';
import moment from 'moment';
import { TEST_LABEL_ID } from '../pages/roadmap';
import {
  ALL_RESOLUTIONS,
  RoadmapFeatureResolution,
  RoadmapETAorVotedFilter,
} from './../types';

export const isRoadmapArticle = (
  articleResolution: ArticleResolution,
  labels: Label[],
  roadmapLabels: Label[]
) => {
  const roadMapLabelsIds = roadmapLabels.map((roadmapLabel) => roadmapLabel.id);
  return (
    ALL_RESOLUTIONS.includes(articleResolution) &&
    labels
      .map((l) => l.id)
      .some(
        (label) =>
          roadMapLabelsIds.includes(label) || TEST_LABEL_ID.includes(label)
      )
  );
};

export const launchedTagText = (eta: Long | number, t: any) => {
  const mum =
    typeof eta === 'number' ? eta : new Long(eta.low, eta.high).toNumber();
  const month = moment.utc(mum).utcOffset('UTC').locale('en').month();
  const monthText = t(`month.${month}`);
  const year = moment.utc(mum).utcOffset('UTC').locale('en').year();
  return t('roadmap.article-list.resolutions.launched', {
    month: monthText,
    year,
  });
};

export const getQuarter = (eta: Long | number) => {
  const mum =
    typeof eta === 'number' ? eta : new Long(eta.low, eta.high).toNumber();

  const monthTZ = moment.utc(mum).utcOffset('UTC').locale('en').month();

  const year = moment.utc(mum).utcOffset('UTC').locale('en').year();

  const quarter = Math.ceil(monthTZ / 3);

  return {
    quarter,
    year,
  };
};

export const statusToClass: Record<FeatureRequestResolutions, string> = {
  [ArticleResolution.COLLECTING_VOTES]: 'collecting-votes',
  [ArticleResolution.PLANNED]: 'planned',
  [ArticleResolution.IN_DEVELOPMENT]: 'in-dev',
  [ArticleResolution.COMING_SOON]: 'pre-launch',
  [ArticleResolution.PENDING_RELEASE]: 'pending-release',
  [ArticleResolution.RELEASED]: 'released',
};

export const FilterToName: Record<RoadmapETAorVotedFilter, string> = {
  [RoadmapETAorVotedFilter.ALL]: 'all features',
  [RoadmapETAorVotedFilter.ETA_NEXT_QUARTER]: 'next-quarter',
  [RoadmapETAorVotedFilter.ETA_THIS_QUARTER]: 'this-quarter',
  [RoadmapETAorVotedFilter.FOLLOWING]: 'following',
};
export const tabToName = (resolution: RoadmapFeatureResolution) => {
  switch (resolution) {
    case 'all':
      return 'all features';
    case ArticleResolution.COLLECTING_VOTES:
      return 'collecting votes';
    case ArticleResolution.PLANNED:
      return 'planned';
    case ArticleResolution.IN_DEVELOPMENT:
      return 'working on it';
    case ArticleResolution.COMING_SOON:
      return 'pre launch';
    case ArticleResolution.RELEASED:
      return 'launched';
    default:
      break;
  }
};

export const sortTypeToName = (sortType: ArticleSortType) => {
  switch (sortType) {
    case ArticleSortType.LAST_PUBLISH_DATE_DESC:
      return 'recently updated';
    case ArticleSortType.RESOLUTION_ETA_DESC:
      return 'closest eta';
    case ArticleSortType.VOTES_DESC:
      return 'most popular';
    default:
      break;
  }
};
const getCurrentQuoter = (eta: Date | number) =>
  Math.ceil((new Date(eta).getUTCMonth() + 1) / 3);

const getNextQuarter = (quarter: number) => (quarter === 4 ? 1 : quarter + 1);

export type Quarter = {
  quarterNumber: number;
  year: number;
};
export const getQuarterFromDate: (date: Date) => Quarter = (date) => {
  const month = moment.utc(date).utcOffset('UTC').locale('en').month() + 1;
  const year = moment.utc(date).utcOffset('UTC').locale('en').year();
  return { quarterNumber: Math.ceil(month / 3), year };
};
export const getDateFromQuarter = (quarter: Quarter) => {
  const { quarterNumber, year } = quarter;
  switch (quarterNumber) {
    case 1:
    case 4:
      return moment
        .utc(`${quarterNumber * 3}/31/${year} 23:59:59`)
        .utcOffset('UTC')
        .locale('en')
        .valueOf();
    default:
      return moment
        .utc(`${quarterNumber * 3}/30/${year} 23:59:59`)
        .utcOffset('UTC')
        .locale('en')
        .valueOf();
  }
};

export const getFormattedDate = (eta: number) =>
  moment.utc(eta).utcOffset('UTC').toISOString().slice(0, -5);

export const getFirstDateOfQuarter = (quarter: Quarter) => {
  const { quarterNumber, year } = quarter;
  return moment
    .utc(`${quarterNumber * 3 - 2}/01/${year} 12:00`)
    .utcOffset('UTC')
    .valueOf();
};

export const getResolutionEtaFilters = (filterType: RoadmapETAorVotedFilter) => {
  const date = new Date();
  if (filterType === RoadmapETAorVotedFilter.ETA_THIS_QUARTER) {
    const quarter = getCurrentQuoter(date);
    const year = date.getUTCFullYear();
    const currentQuarter = {
      quarterNumber: quarter,
      year,
    };

    const firstDateOfQuarter = getFirstDateOfQuarter(currentQuarter);
    const selectedToQuarterEnd = getDateFromQuarter(currentQuarter);

    return {
      fromResolutionEta: getFormattedDate(firstDateOfQuarter),
      toResolutionEta: getFormattedDate(selectedToQuarterEnd),
      hasResolutionEta: true,
    };
  }
  if (filterType === RoadmapETAorVotedFilter.ETA_NEXT_QUARTER) {
    const quarter = getNextQuarter(getCurrentQuoter(date));
    const year =
      quarter === 1 ? date.getUTCFullYear() + 1 : date.getUTCFullYear();
    const nextQuarter = {
      quarterNumber: quarter,
      year,
    };
    const firstDateOfQuarter = getFirstDateOfQuarter(nextQuarter);
    const selectedToQuarterEnd = getDateFromQuarter(nextQuarter);
    return {
      fromResolutionEta: getFormattedDate(firstDateOfQuarter),
      toResolutionEta: getFormattedDate(selectedToQuarterEnd),
      hasResolutionEta: true,
    };
  }
  return {
    fromResolutionEta: null,
    toResolutionEta: null,
    hasResolutionEta: null,
  };
};

export type EventRoadmapData = {
  clickedUrl?: string;
  clickedText?: string;
  clickedItemOrder?: string;
  clickedItemId?: string;
  itemId?: string;
};

export const sortRoadmapLabels = (allLabels: Label[]) => {
  const startsWithWixLabels: Label[] = [];
  const standaloneLabels: Label[] = [];

  allLabels.forEach((label) => {
    if (label.name.trim().startsWith('Wix')) {
      startsWithWixLabels.push(label);
    } else {
      standaloneLabels.push(label);
    }
  });

  standaloneLabels.sort((labelA: Label, labelB: Label) =>
    labelA.name.localeCompare(labelB.name)
  );
  startsWithWixLabels.sort((labelA: Label, labelB: Label) =>
    labelA.name.localeCompare(labelB.name)
  );

  return [...standaloneLabels, ...startsWithWixLabels];
};

export const getRoadmapLabels = (allLabels: Label[]) => {
  const roadmapLabels = allLabels
    .filter((l) => l.name.startsWith('Roadmap:'))
    .map((roadmapLabel) => ({
      ...roadmapLabel,
      name: roadmapLabel.name.replace('Roadmap:', ''),
    }));

  return sortRoadmapLabels(roadmapLabels);
};
