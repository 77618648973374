import { useRouter } from 'next/router';
import { Article } from '@wix/answers-api';
import { Box, Text } from '@wix/design-system';
import { FunctionComponent } from 'react';
import { useExperiments } from '@wix/fe-essentials-standalone';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { useTranslation } from '@wix/wix-i18n-config';
import { Anchors, ConsumerSource } from '@wix/cc-user-facing-common-components';
import classNames from 'classnames';
import { BI, EXPERIMENTS, LOCALES } from '../../constants';
import { Step } from '../../pages/article/[articleTitle]';
import { mapArticleTypeToItemType } from '../../utils/bi';
import { ArticleFeedback } from '../ArticleFeedback';
import { VisibleFeedback } from '../ArticleVisibleFeedback';
import { useBI } from '../../hooks/useBI';
import css from './index.module.scss';

type ArticleSideBarProps = {
  article: Article;
  steps: Step[];
};

export const ArticleSideBar: FunctionComponent<ArticleSideBarProps> = ({
  article,
  steps,
}: ArticleSideBarProps) => {
  const { locale } = useRouter();
  const { t } = useTranslation();
  const { sendBIEvent } = useBI();
  const { experiments } = useExperiments({ readOnly: true });

  const isNewFeedbackEnabled = (
    (experiments.enabled(EXPERIMENTS.SPECS.VISIBLE_ARTICLE_FEEDBACK) && locale === LOCALES.EN) ||
    (experiments.enabled(EXPERIMENTS.SPECS.VISIBLE_ARTICLE_FEEDBACK_ALL_LOCALES) && locale !== LOCALES.EN)
  );

  const onAnchorClick = async (text: string, order: string) => {
    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.ARTICLE,
        kb_lang: locale as string,
        item_id: article.id,
        item_type: mapArticleTypeToItemType(article.type),
        clicked_item_type: BI.CLICKED_ITEM_TYPES.SIDEBAR_ANCHOR,
        clicked_text: text,
        clicked_item_order: order,
        is_page_content_click: true,
      })
    );
  };

  return (
    <Box direction="vertical" className={classNames(css.wrapper, css.stickyWrapper)}>
      {steps.length > 0 && <Box className={css.anchorsWrapper} direction="vertical">
        <Text weight="bold">{t('scrollspy.title')}</Text>
        <Anchors steps={steps} onClick={onAnchorClick} hideTitle={true} locale={locale as string} consumerSource={ConsumerSource.HELP_CENTER} />
      </Box>}
      <>
        {isNewFeedbackEnabled ? <VisibleFeedback articleId={article.id} categoryId={article.categoryId} /> :
          <ArticleFeedback articleId={article.id} categoryId={article.categoryId} />}
      </>
    </Box>
  );
};
