import { ArticleResolution, FeatureRequestResolutions } from '@wix/answers-api';
import { Tag, Text, Tooltip } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import Long from 'long';
import { FunctionComponent } from 'react';
import { DATA_HOOKS } from '../../dataHooks';
import { launchedTagText } from '../../utils/roadmap';
import css from './index.module.scss';
import { useRouter } from 'next/router';
import { statusToClassLocalize } from '../../utils/roadmapLocalizationKit';
import { MOVE_TO_TOP_Z_INDEX } from '../../constants';

export type RoadmapResolutionBadgeProps = {
  resolution: ArticleResolution;
  size: 'tiny' | 'small';
  resolutionEta?: Long | number | null;
  showTooltip?: boolean;
  onClickStatus?: (
    articleResolution: ArticleResolution,
    clickedText: string
  ) => void;
};

export const RoadmapResolutionBadge: FunctionComponent<
  RoadmapResolutionBadgeProps
> = ({ resolution: originalResolution, size, resolutionEta, onClickStatus, showTooltip }) => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  // pending release is a special case, we want to show as coming soon
  const resolution = originalResolution === ArticleResolution.PENDING_RELEASE ? ArticleResolution.COMING_SOON : originalResolution;

  const getClassFunction = statusToClassLocalize(locale);

  const resolutionClass =
    getClassFunction[(resolution as unknown as FeatureRequestResolutions) || ArticleResolution.COLLECTING_VOTES];

  const articleItemStatus =
    resolution === ArticleResolution.RELEASED && resolutionEta
      ? launchedTagText(resolutionEta, t)
      : t(`roadmap.article-list.resolutions.${resolution}`);

  const renderStatusBody = () => (
    <Tag
      size={size}
      className={`${css.statusTag} ${css[resolutionClass]}`}
      id={`${resolution}`}
      theme="warning"
      dataHook={DATA_HOOKS.ROADMAP_RESOLUTION_BADGE}
      removable={false}
      onClick={
        onClickStatus
          ? () => onClickStatus(resolution, articleItemStatus)
          : undefined
      }
    >
      <div className={`${css.statusBody}`}>
        <Text
          dataHook={DATA_HOOKS.ARTICLE_ITEM_STATUS}
          datatype={`${resolution}`}
          size={size}
          className={css.resolutionText}
        >
          {articleItemStatus}
        </Text>
      </div>
    </Tag>
  );

  return (
    <div
      className={`${css.roadmapResolutionBadge} ${
        onClickStatus ? 'clickable' : ''
      }`}
    >
      {showTooltip ? (
        <Tooltip
          size="small"
          disabled={!showTooltip}
          zIndex={MOVE_TO_TOP_Z_INDEX}
          content={t(`roadmap.article-list.resolution.tooltip.${resolution}`)}
        >
          {renderStatusBody()}
        </Tooltip>
      ) : (
        renderStatusBody()
      )}
    </div>
  );
};
